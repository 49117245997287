import React, { useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import { connect } from 'react-redux';
import { toggleOpenBooking } from 'redux/Actions/ui-actions';

import styles from './LandingView.module.scss';

import ModalStart from '../../components/Modal/modal-start';
import Booking from '../../components/Booking/booking';
import Description from '../../components/Description/description';
import Map from '../../components/Map/map';
import Footer from '../../components/Footer/footer';
import Regulations from '../../components/Regulations/regulations';

const LandingView = ({ background, backgroundMedium, openBooking, openBookingFUNC }) => {
  const [showRegulations, setShowRegulations] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showRodo, setShowRodo] = useState(false);
  const [showCookieBar, setShowCookieBar] = useState(true);

  const handleShowRegulations = () => {
    setShowRegulations(!showRegulations);
  };
  const handleShowPrivacy = () => {
    setShowPrivacy(!showPrivacy);
  };
  const handleShowRodo = () => {
    setShowRodo(!showRodo);
  };
  const handleCloseBoxRegulation = () => {
    setShowRegulations(false);
    setShowPrivacy(false);
    setShowRodo(false);
  };

  return (
    <>
      <div>
        <picture>
          <source media="(min-width:996px)" srcSet={`${background}`} />
          <img
            className={openBooking ? styles.imageFixed : styles.image}
            src={backgroundMedium}
            alt="Tło"
          />
        </picture>
        <div className={styles.container}>
          <div className={styles.section}>
            <div className={styles.box}>
              {openBooking ? (
                <Booking openBooking={openBookingFUNC} />
              ) : (
                <ModalStart openBooking={openBookingFUNC} />
              )}
            </div>
          </div>
        </div>
      </div>
      {showCookieBar && (
        <CookieConsent
          location="bottom"
          buttonText="Akceptuj"
          cookieName="Cookie"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#2B373B',
            textAlign: 'center',
            paddingRight: '20px',
            paddingLeft: '10px',
          }}
          buttonStyle={{
            background: '#199cdb',
            color: 'white',
            fontSize: '16px',
            borderRadius: '4px',
            fontFamily: 'Poppins',
          }}
        >
          Klikając Akceptuj, wyrażają Państwo zgodę na używanie przez nas plików cookie.&nbsp;
          <button
            className={styles.cookie__buttonTerm}
            type="button"
            onClick={handleShowRegulations}
          >
            Regulamin serwisu.
          </button>
          <button
            className={styles.cookie__buttonClose}
            type="button"
            onClick={() => setShowCookieBar(!showCookieBar)}
          >
            X
          </button>
        </CookieConsent>
      )}

      {!openBooking && (
        <>
          <div className={styles.container}>
            <Description />
            <Map />
          </div>
          {showRegulations && (
            <Regulations
              handleShowRegulations={handleShowRegulations}
              handleCloseBoxRegulation={handleCloseBoxRegulation}
            />
          )}
          {showPrivacy && (
            <Regulations
              handleShowPrivacy={handleShowPrivacy}
              handleCloseBoxRegulation={handleCloseBoxRegulation}
            />
          )}
          {showRodo && (
            <Regulations
              handleShowRodo={handleShowRodo}
              handleCloseBoxRegulation={handleCloseBoxRegulation}
            />
          )}
          <Footer
            handleShowRegulations={handleShowRegulations}
            handleShowPrivacy={handleShowPrivacy}
            handleShowRodo={handleShowRodo}
          />
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  openBookingFUNC: () => dispatch(toggleOpenBooking()),
});

const mapStateToProps = (state) => ({
  openBooking: state.UI.openBooking,
});

export default connect(mapStateToProps, mapDispatchToProps)(LandingView);
