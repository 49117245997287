import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import styles from './header-navigation.module.scss';
import Navigation from './components/navigation';
import Flag from '../../assets/flag-poland.svg';

const HeaderNavigation = (props) => {
  const { openBooking } = props;
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const [logo, setLogo] = useState([]);
  const [loadingLogo, setLoadingLogo] = useState(false);

  useEffect(() => {
    setLoadingLogo(true);
    axios({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      url: 'https://api.bergregions.pl/api/v1/tv-app/preview/29307792',
    })
      .then((response) => {
        // handle success
        setLogo(response.data.logo.sizes.find((o) => o.size === 'thumbnail').url);
        setLoadingLogo(false);
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error) => {
        // handle error
      });
  }, []);

  return (
    <header className={styles.header}>
      <div className={styles.header__menu}>
        {loadingLogo && <div className={styles.header__logoPlaceHolder} />}
        <NavLink to="/">
          <img
            className={!loadingLogo ? styles.header__logo : styles.header__logoHide}
            src={logo}
            alt="Skoda L'emir"
          />
        </NavLink>
        {openBooking ? null : (
          <>
            <Navigation />
            <div className={styles.country}>
              <button
                className={styles.country__button}
                type="button"
                onClick={() => changeLanguage('pl')}
              >
                <img className={styles.country__flag} src={Flag} alt="Flaga wybranego języka" />
              </button>
              <p className={styles.country__name}>PL</p>
            </div>
          </>
        )}
      </div>
    </header>
  );
};

const mapStateToProps = (state) => ({
  openBooking: state.UI.openBooking,
});

export default connect(mapStateToProps)(HeaderNavigation);
