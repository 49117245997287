import * as actionTypes from '../Types/ui-types';

export const toggleOpenBooking = () => ({
  type: actionTypes.OPEN_BOOKING,
});

export const toggleOpenForm = () => ({
  type: actionTypes.OPEN_FORM,
});

export const toggleShowMoreForm = () => ({
  type: actionTypes.SHOW_MORE_FORM,
});

export const toggleShowVerification = () => ({
  type: actionTypes.SHOW_VERIFICATION,
});

export const toggleShowRent = () => ({
  type: actionTypes.SHOW_RENT,
});

export const toggleShowConfirmation = () => ({
  type: actionTypes.SHOW_CONFIRMATION,
});

export const toggleShowTerm = () => ({
  type: actionTypes.SHOW_TERM,
});
