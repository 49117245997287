import React from 'react';
import { connect } from 'react-redux';

import { toggleShowTerm } from 'redux/Actions/ui-actions';
import { toggleAcceptTerm } from 'redux/Actions/form-actions';
import { useTranslation } from 'react-i18next';

import styles from './term.module.scss';

import TermsOfService from '../../../Regulations/components/termsOfService';
import Button from '../../../Button/button';
import ArrowBack from '../../../../assets/arrow-back.svg';
import Bird from '../../../../assets/checkbox-bird-white.svg';

const Term = (props) => {
  const { showTermFUNC, acceptTermFUNC } = props;

  const { t } = useTranslation();

  const handleBackView = () => {
    showTermFUNC();
  };

  const handleAcceptTerm = () => {
    showTermFUNC();
    acceptTermFUNC();
  };

  return (
    <div className={styles.term}>
      <header>
        <button type="button" className={styles.term__button} onClick={handleBackView}>
          <img src={ArrowBack} alt="Strzałka powrót" />
        </button>
      </header>
      <TermsOfService />
      <div className={styles.buttons}>
        <Button secondary onPress={handleBackView}>
          {t('termButtonBack')}
        </Button>
        <Button onPress={handleAcceptTerm}>
          {t('termButtonAccept')}
          <img className={styles.buttons__bird} src={Bird} alt="Ok" />
        </Button>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  showTermFUNC: () => dispatch(toggleShowTerm()),
  acceptTermFUNC: () => dispatch(toggleAcceptTerm()),
});

export default connect(null, mapDispatchToProps)(Term);
