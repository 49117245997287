export const ADD_PHONE_NUMBER = 'ADD_PHONE_NUMBER';
export const ADD_ARRIVAL_DATE = 'ADD_ARRIVAL_DATE';
export const ADD_NAME = 'ADD_NAME';
export const ADD_REGISTRATION_NUMBER = 'ADD_REGISTRATION_NUMBER';
export const ADD_STREET = 'ADD_STREET';
export const ADD_HOUSE_NUMBER = 'ADD_HOUSE_NUMBER';
export const ADD_CITY = 'ADD_CITY';
export const ADD_POST_CODE = 'ADD_POST_CODE';
export const ADD_DESCRIPTION = 'ADD_DESCRIPTION';
export const ADD_PIN_CODE = 'ADD_PIN_CODE';
export const ADD_STATUS_VERIFICATION = 'ADD_STATUS_VERIFICATION';
export const CHECKED__TERM = 'CHECKED__TERM';
export const ACCEPT__TERM = 'ACCEPT__TERM';
export const RESET_DATA = 'RESET_DATA';
