import React from 'react';

import styles from './mainForTerms.module.scss';

const PrivacyPolicy = () => (
  <>
    <header>
      <h2 className={styles.box__header}>Polityka prywatności</h2>
    </header>
    <div className={styles.box}>
      <p>
        Volkswagen Group Polska Sp. z o.o. gwarantuje bezpieczeństwo danych osobowych dla
        użytkownik&oacute;w odwiedzających stronę internetową www.skoda-auto.pl. Zamieszczone
        poniżej informacje gwarantują zachowanie poufności danych osobowych oraz ich właściwe
        wykorzystanie.{' '}
      </p>
      <h3>
        <strong>Zbieranie i wykorzystanie informacji</strong>
      </h3>
      <p>
        Volkswagen Group Polska Sp. z o.o. jest wyłącznym właścicielem informacji zgromadzonych w
        tym serwisie. Volkswagen Group Polska Sp. z o.o. nie będzie tych informacji sprzedawać,
        wydzierżawiać ani udostępniać osobom trzecim w spos&oacute;b niezgodny z zasadami podanymi
        poniżej.
      </p>
      <h3>
        <strong>Anonimowość</strong>
      </h3>
      <p>
        Internauci korzystający z naszego serwisu pozostają anonimowi do czasu aż zadecydują, że
        chcą się zalogować. Informacje zawarte w logach systemowych są przez Volkswagen Group Polska
        Sp. z o.o.. wykorzystywane w celach technicznych, związanych z administracją naszego
        serwera. Ponadto Volkswagen Group Polska Sp. z o.o. zastrzega sobie prawo do korzystania z
        tych informacji na potrzeby związane ze zbieraniem statystycznych informacji
        demograficznych.
      </p>
      <h3>
        <strong>Cookies</strong>
      </h3>
      <p>
        Pliki cookies to niewielkie informacje tekstowe, wysyłane przez portal i zapisywane na
        komputerze Użytkownika (bądź innym urządzeniu, z kt&oacute;rego korzysta). Nie używamy
        cookies do gromadzenia danych osobowych, takich jak: imię, nazwisko lub adres e-mail.
      </p>
      <p>
        Portal wykorzystuje pliki cookies w celu rozpoznawania osobistych preferencji Użytkownika.
        Innymi słowy stosowane przez portal pliki cookies pozwalają na rozpoznanie komputera
        Użytkownika w czasie kolejnej wizyty w portalu i mają jedynie ułatwiać korzystanie z
        portalu. Portal wykorzystuje oraz umożliwia korzystanie z plik&oacute;w cookies podmiotom
        trzecim (Google AdWords, GoogleAnalytics) w celach statystycznych oraz reklamowych.
      </p>
      <p>
        To Użytkownik decyduje, czy chce, aby pliki cookies były zapisywane na jego komputerze. Może
        dezaktywować lub całkowicie wyłączyć pliki cookies w opcjach swojej przeglądarki
        internetowej lub wybierając odpowiednią opcję w programach typu Firewall. Może
        r&oacute;wnież usunąć zapisane do tej pory pliki cookies. Użytkownik może zablokować usługę
        Analytics dla reklam displayowych oraz dostosowywać reklamy w sieci reklamowej Google w
        Menedżerze preferencji reklam.
      </p>
      <p>
        Wyłączenie plik&oacute;w cookies może jednak spowodować, niedogodności w korzystaniu z
        naszego serwisu.
      </p>
      <h3>
        <strong>Zam&oacute;wienia</strong>
      </h3>
      <p>
        W celu uzyskania dostępu do wszystkich usług dostępnych w serwisie, niezbędne jest
        zalogowanie się za pomocą hasła. Wyłączenie cookies w przeglądarce może spowodować
        utrudnienia w korzystaniu z naszych usług. Założenie konta na www.skoda-auto.pl wymaga
        podania informacji o użytkowniku poprzez wypełnienie formularza, w kt&oacute;rym należy
        podać informacje kontaktowe (imię, nazwisko, adres, numer telefonu) oraz informacje
        demograficzne (wiek, zaw&oacute;d).
      </p>
      <p>
        Informacje zawarte w formularzu są wykorzystywane przez Volkswagen Group Polska Sp. z o.o.
        do kontakt&oacute;w z użytkownikami serwisu oraz do ciągłego dostosowywania zawartych na
        stronie informacji do potrzeb i oczekiwań naszych użytkownik&oacute;w.
      </p>
      <h3>
        <strong>Subskrypcje</strong>
      </h3>
      <p>
        Aby otrzymać pewne informacje o naszej firmie, konieczne jest podanie przez użytkownika
        swojego adresu e-mail. Adres ten będzie wykorzystywany przez Volkswagen Group Polska Sp. z
        o.o. tylko w celu wysłania żądanych informacji. Jeżeli użytkownik chce zrezygnować z tej
        usługi, może w każdej chwili anulować subskrypcję.
      </p>
      <h3>
        <strong>Ochrona danych i zachowanie poufności</strong>
      </h3>
      <p>
        Wszystkie dane o użytkownikach uzyskane przez Volkswagen Group Polska Sp. z o.o. za
        pośrednictwem tego serwisu są przechowywane i przetwarzane w spos&oacute;b zapewniający ich
        bezpieczeństwo, zgodnie z przepisami polskiego prawa.
      </p>
      <p>
        Jednocześnie prosimy o zachowanie ostrożności przy opuszczaniu naszego serwisu, aby nikt
        postronny nie m&oacute;gł wykorzystywać lub zmieniać Twoich informacji. Prosimy też o
        zachowanie ostrożności w udostępnianiu ich osobom trzecim.
      </p>
      <h3>
        <strong>Zmiany w zasadach zachowania poufności</strong>
      </h3>
      <p>
        Volkswagen Group Polska Sp. z o.o. zastrzega sobie prawo do wprowadzania zmian w niniejszym
        oświadczeniu. W związku z tym prosimy użytkownik&oacute;w o okresowe sprawdzanie treści
        oświadczenia w celu zapoznania się z ewentualnymi zmianami.
      </p>
    </div>
  </>
);

export default PrivacyPolicy;
