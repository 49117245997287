import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import axios from 'axios';
import ReactGA from 'react-ga';

import GlobalStyle from 'theme/GlobalStyle';

import store from '../redux/store';
import HeaderNavigation from '../components/Navigation/header-navigation';
import LandingView from './LandingView/LandingView';
import OfferView from './OfferView/OfferView';
import QrView from './QrView/QrView';

export const initGA = () => {
  ReactGA.initialize('G-L845DMDQQ2');
  ReactGA.pageview(window.location.pathname + window.location.search);
};

const App = () => {
  const [background, setBackground] = useState([]);
  const [backgroundMedium, setBackgroundMedium] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [errorBackground, setErrorBackground] = useState([]);
  useEffect(() => {
    initGA();
  }, []);

  useEffect(() => {
    axios({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      url: 'https://api.bergregions.pl/api/v1/tv-app/preview/29307792',
    })
      .then((response) => {
        // handle success
        setBackground(response.data.backgroundMedia.sizes.find((o) => o.size === 'large').url);
        setBackgroundMedium(
          response.data.backgroundMedia.sizes.find((o) => o.size === 'medium').url,
        );
      })
      // eslint-disable-next-line no-shadow
      .catch((error) => {
        // handle error
        setErrorBackground(error);
      });
  }, []);

  return (
    <Provider store={store}>
      <GlobalStyle />
      <Router>
        <HeaderNavigation />
        <Switch>
          <Route exact path="/">
            <LandingView background={background} backgroundMedium={backgroundMedium} />
          </Route>
          <Route exact path="/uslugi">
            <OfferView background={background} backgroundMedium={backgroundMedium} />
          </Route>
          <Route path="/qr/:code">
            <QrView />
          </Route>
        </Switch>
      </Router>
    </Provider>
  );
};

export default App;
