import React from 'react';

import styles from './mainForTerms.module.scss';

const TermsOfService = () => (
  <>
    <header>
      <h2 className={styles.box__header}>
        REGULAMIN ŚWIADCZENIA USŁUG DROGĄ ELEKTRONICZNĄ ZA POŚREDNICTWEM PORTALU „L’emirGO”
      </h2>
    </header>
    <div className={styles.box}>
      <h3>
        <strong>Postanowienia Ogólne</strong>
        <p>
          <strong>&sect;1</strong>
        </p>
      </h3>
      <ol>
        <li>
          Niniejszy dokument określa w szczeg&oacute;lności zasady świadczenia i korzystania przez
          Użytkownik&oacute;w z usług elektronicznych oferowanych za pośrednictwem Portalu{' '}
          <strong>L&rsquo;emirGO</strong> przez Usługodawcę, w tym proces dokonywania Rezerwacji
          oraz warunki Um&oacute;w Usługi <strong>L&rsquo;emirGO</strong>.
        </li>
        Niniejszy dokument określa prawa i obowiązki Usługodawcy oraz Użytkownik&oacute;w i jest
        regulaminem, o kt&oacute;rym mowa w art. 8 ustawy z dnia 18 lipca 2002 r. o świadczeniu
        usług drogą elektroniczną (Dz.U. z 2002 r. Nr 144, poz. 1204 z p&oacute;źn. zm.).
        <li>
          Usługodawca świadczy usługi drogą elektroniczną za pośrednictwem Portalu{' '}
          <strong>L&rsquo;emirGO</strong> w zakresie określonym w &sect;4 niniejszego Regulaminu.
        </li>
        <li>
          Niniejszy Regulamin jest udostępniany nieodpłatnie na Stronie internetowej Usługodawcy,
          funkcjonującej w domenie (pod adresem) www.lemir24.pl, w ramach kt&oacute;rej prowadzony
          jest r&oacute;wnież Portal <strong>L&rsquo;emirGO</strong>.<br />
          Niniejszy Regulamin udostępniany jest w formie umożliwiającej jego pozyskanie,
          odtwarzanie, utrwalanie i wydrukowanie. Użytkownik/ Reprezentant Użytkownika ma możliwość
          zapoznania się z treścią Regulaminu w każdym czasie za pomocą odsyłacza (linku)
          zamieszczonego pod adresem w/w Strony internetowej, zakładka &bdquo;Regulamin{' '}
          <strong>L&rsquo;emirGO</strong>&rdquo;.
        </li>
        <li>
          Rozpoczęcie korzystania z usług elektronicznych oferowanych w ramach Portalu{' '}
          <strong>L&rsquo;emirGO</strong> jest r&oacute;wnoznaczne z akceptacją warunk&oacute;w
          niniejszego Regulaminu i tym samym z zawarciem z Usługodawcą umowy o świadczenie usług
          drogą elektroniczną.
        </li>
        <li>
          Usługodawca nie pobiera żadnych opłat za komunikację za pośrednictwem Platformy{' '}
          <strong>L&rsquo;emirGO</strong> i świadczone w jej ramach usługi elektroniczne lub w
          związku z wykorzystaniem środk&oacute;w porozumiewania się na odległość.
          Użytkownik/Reprezentant Użytkownika ponosi te koszty w wysokości wynikającej z odrębnej
          umowy zawartej pomiędzy Użytkownikiem /Reprezentantem Użytkownika i osobą trzecią
          świadczącą na rzecz Użytkownika /Reprezentanta Użytkownika usługi umożliwiające
          porozumiewanie się na odległość.
        </li>
        <li>
          <strong>Użytkownik zobowiązany jest do przestrzegania postanowień Regulaminu</strong>.
        </li>
      </ol>
      <h3>
        <strong>Podmiot (Usługodawca) oferujący usługi świadczone drogą elektroniczną</strong>
        <p>
          <strong>&sect;2</strong>
        </p>
      </h3>
      Podmiotem (Usługodawcą) oferującym usługi świadczone drogą elektroniczną za pośrednictwem
      Portalu &bdquo;<strong>L&rsquo;emirGO</strong>&rdquo; jest:&nbsp;
      <strong>
        L&apos;emir Sp. z o.o., ul. Sobieskiego 16, 41-300 Dąbrowa G&oacute;rnicza, NIP:
        629-000-76-04. REGON 271074824, wpisana do rejestru przedsiębiorc&oacute;w prowadzonego
        przez Sąd Rejonowy Katowice-Wsch&oacute;d w Katowicach Wydział VII Gospodarczy KRS nr
        0000956642,{' '}
      </strong>
      <strong>Email: serwis@lemir.com.pl, </strong>
      <strong>telefon :325088000, </strong>
      <h3>
        <strong>
          Definicje i znaczenie zwrot&oacute;w oraz termin&oacute;w użytych w niniejszym Regulaminie
        </strong>
        <p>
          <strong>&sect;3</strong>
        </p>
      </h3>
      Definicje, zwroty i terminy użyte w niniejszym Regulaminie oznaczają:
      <ul>
        <li>
          1) <strong>APS &ndash;</strong> serwis samochodowy prowadzony pod adresem ul. Sobieskiego
          10A, 41-300 Dąbrowa G&oacute;rnicza, będący częścią przedsiębiorstwa Usługodawcy;
        </li>
        <li>
          2) <strong>Kalkulacja </strong>&ndash; informacja
          <em>
            &ndash; sporządzana po dokonaniu Rezerwacji i po zdaniu kluczyka do Skrytki i Pojazdu
            Użytkownika na terenie APS, w uzgodnieniu z Użytkownikiem -
          </em>
          o oszacowanym zakresie i kosztach Prac serwisowych na Pojeździe Użytkownika.
          <strong>
            Wyżej wymieniona Kalkulacja (informacja o zakresie i kosztach Prac serwisowych) nie
            stanowi oferty w rozumieniu Kodeksu Cywilnego, lecz zaproszenie do zawarcia Umowy Prac
            Serwisowych i/lub Umowy Najmu Pojazdu, o kt&oacute;rej mowa w art. 71 Kodeksu Cywilnego
          </strong>
          .
        </li>
        <li>
          3) <strong>Kod QR</strong> &ndash; unikatowy kod przekazywany wiadomością tekstową sms, na
          numer telefonu Użytkownika /Reprezentanta Użytkownika podany przy dokonywaniu Rezerwacji,
          kt&oacute;ry to umożliwia Użytkownikowi /Reprezentantowi Użytkownika pozostawienie w
          Skrytce kluczyka do Pojazdu Użytkownika w celu ustalenia zakresu i koszt&oacute;w Prac
          serwisowych w tym pojeździe i otrzymania Kalkulacji, jak r&oacute;wnież umożliwiający
          Użytkownikowi/ Reprezentantowi Użytkownika pobranie (odebranie) kluczyka do Pojazdu
          Użytkownika;
        </li>
        <li>
          4) <strong>Konsument</strong> &ndash; osoba będąca konsumentem w rozumieniu art. 22(1)
          ustawy z dnia 23 kwietnia 1964 r. Kodeks Cywilny;
        </li>
        <li>
          5) <strong>Numer PIN (lub PIN)</strong> &ndash; ciąg cyfr przekazywany wiadomością
          tekstową sms na numer telefonu Użytkownika /Reprezentanta Użytkownika podany przy
          dokonywaniu Rezerwacji, kt&oacute;ry to umożliwia Użytkownikowi /Reprezentantowi
          Użytkownika pozostawienie w Skrytce kluczyka do Pojazdu Użytkownika w celu ustalenia przez
          Usługodawcę zakresu i koszt&oacute;w Prac serwisowych w tym pojeździe, jak r&oacute;wnież
          umożliwiający Użytkownikowi/ Reprezentantowi Użytkownika pobranie (odebranie) kluczyka do
          Pojazdu Użytkownika;
        </li>
        <li>
          6) <strong>Platforma L&rsquo;emirGO (lub Platforma)</strong> &ndash; funkcjonujące w
          ramach Strony internetowej prowadzonej w domenie www.lemir24.pl rozwiązanie
          teleinformatyczne umożliwiające, za pośrednictwem środk&oacute;w porozumiewania się na
          odległość, zawarcie między Usługodawcą a Użytkownikiem, Umowy Usługi{' '}
          <strong>L&rsquo;emirGO</strong> opisanej w niniejszym Regulaminie i na warunkach w nim
          określonych;
        </li>
        <li>
          7) <strong>Pojazd Usługodawcy</strong> &ndash; samoch&oacute;d osobowy w rozumieniu ustawy
          z dnia 20 czerwca 1997 r. Prawo o ruchu drogowy (t.j. Dz.U.2020.110 z p&oacute;źn. zm.),
          tj. pojazd samochodowy przeznaczony konstrukcyjnie do przewozu nie więcej niż 9
          os&oacute;b łącznie z kierowcą oraz ich bagażu, stanowiący własność Usługodawcy lub
          wykorzystywany przez niego w oparciu o inny tytuł prawny (jak przykładowo zawarta przez
          Usługodawcę z właścicielem tego pojazdu umowa leasingu, umowa najmu), kt&oacute;ry jest
          przedmiotem Umowy Najmu Pojazdu zawartej na warunkach odrębnie uzgodnionych między
          Użytkownikiem (jako najemcą) a Usługodawcą (jako wynajmującym) za pośrednictwem
          środk&oacute;w porozumiewania się na odległość lub przy bezpośredniej obecności stron tej
          umowy;
        </li>
        <li>
          8) <strong>Pojazd Użytkownika</strong> &ndash; samoch&oacute;d osobowy w rozumieniu ustawy
          z dnia 20 czerwca 1997 r. Prawo o ruchu drogowy (t.j. Dz.U.2020.110 z p&oacute;źn. zm.),
          (tj. pojazd samochodowy przeznaczony konstrukcyjnie do przewozu nie więcej niż 9
          os&oacute;b łącznie z kierowcą oraz ich bagażu), kt&oacute;rego Użytkownik jest
          właścicielem lub do kt&oacute;rego posiada tytuł prawny obejmujący do dysponowania nim, w
          tym w zakresie jego pozostawienia na terenie APS i złożenie kluczyka do tego samochodu do
          Skrytki w celu ustalenia zakresu i koszt&oacute;w Prac serwisowych w tym samochodzie;
        </li>
        <li>
          9) <strong>Prace serwisowe</strong> &ndash; usługa/i obejmująca/e w szczeg&oacute;lności
          prace serwisowe, kt&oacute;rych wstępny zakres i koszty określa Kalkulacja, będące
          przedmiotem odrębnej Umowy Prac Serwisowych, realizowana/e na Pojeździe Użytkownika w
          zakresie odrębnie ustalonym między Usługodawcą a Użytkownikiem w tej Umowie Prac
          serwisowych.
          <br />
          <strong>
            Prace Serwisowe nie obejmują napraw blacharsko lakierniczych. Usługi/prace w zakresie
            napraw blacharsko lakierniczych, w tym &bdquo;powypadkowych&rdquo; nie są Pracami
            Serwisowymi.
          </strong>
        </li>
        <li>
          10) <strong>Przedsiębiorca</strong> &ndash; podmiot (w tym osoba fizyczna, osoba prawna,
          jednostka organizacyjna w rozumieniu art. 33(1) Kodeksu Cywilnego) posiadająca status
          przedsiębiorcy w rozumieniu art. 43(1) Kodeksu Cywilnego, prowadząca działalność na
          podstawie wpisu do Centralnej Ewidencji i Informacji o Działalności Gospodarczej lub
          ujawniona w Krajowym Rejestrze Sądowym, lub ujawniona w innym właściwym państwowym
          rejestrze, a w wypadku podmiotu zagranicznego posiadająca status przedsiębiorcy na mocy
          prawa właściwego dla tego podmiotu i w oparciu o właściwy wpis do odpowiedniego rejestru
          prowadzonego w kraju jego siedziby;
        </li>
        <li>
          11) <strong>Regulamin</strong> &ndash; niniejszy dokument, stanowiący r&oacute;wnież
          regulamin świadczenia usług drogą elektroniczną;
        </li>
        <li>
          12) <strong>Reprezentant Użytkownika</strong> &ndash; osoba korzystająca z usług
          elektronicznych świadczonych za pośrednictwem Portalu <strong>L&rsquo;emirGO</strong> i
          zawierająca z Usługodawcą w imieniu Użytkownika Umowę Usługi{' '}
          <strong>L&rsquo;emirGO</strong>, będąca osobą uprawnioną do reprezentowania Użytkownika w
          świetle Krajowego Rejestru Sądowego, lub Centralnej Ewidencji i Informacji o Działalności
          Gospodarczej lub na mocy innego stosunku (tytułu) prawnego np. pełnomocnictwo;
        </li>
        <li>
          13) <strong>Rezerwacja</strong> &ndash; wykonanie czynności niezbędnych w świetle
          Regulaminu do zawarcia Umowy Usługi <strong>L&rsquo;emirGO</strong> opisanej w pkt 22
          niniejszego paragrafu
        </li>
        <li>
          14) <strong>Skrytka</strong> &ndash; urządzenie, znajdujące się na terenie APS, będące
          automatyczną skrytką, w kt&oacute;rej znajdują się schowki wyposażone w zamki
          elektroniczne, kt&oacute;rych otwarcie możliwe jest przy wykorzystaniu kodu QR lub numeru
          PIN, służąca przede wszystkim do składania w niej przez Użytkownika kluczyka do Pojazdu
          Użytkownika,
        </li>
        <li>
          15) <strong>Umowa o świadczenie usług drogą elektroniczną &ndash;</strong> umowa zawarta
          między Usługodawcą (L&rsquo;emir) a Użytkownikiem, mająca za przedmiot usługę lub usługi
          świadczone drogą elektroniczną (opisane w &sect;4 niniejszego Regulaminu);
        </li>
        <li>
          16) <strong>Usługodawca (lub &bdquo;L&rsquo;emir&rdquo;) &ndash;</strong> podmiot wskazany
          w &sect;2 niniejszego Regulaminu, a więc L&apos;emir Sp. z o.o., ul. Sobieskiego 10A,
          41-300 Dąbrowa G&oacute;rnicza, NIP: 629-000-76-04. REGON 271074824, wpisana do rejestru
          przedsiębiorc&oacute;w prowadzonego przez Sąd Rejonowy Katowice-Wsch&oacute;d w Katowicach
          Wydział VII Gospodarczy KRS nr 0000956642. Dane kontaktowe Usługodawcy wskazano w &sect;2
          niniejszego Regulaminu;
        </li>
        <li>
          17) <strong>Użytkownik -</strong> osoba fizyczna, prawna lub jednostka organizacyjna (w
          tym będąca konsumentem albo przedsiębiorcą w rozumieniu Kodeksu Cywilnego), kt&oacute;ra
          osobiście lub poprzez Reprezentanta Użytkownika korzysta z oferowanych przez Usługodawcę
          usług świadczonych drogą elektroniczną za pośrednictwem Portalu{' '}
          <strong>L&rsquo;emirGO</strong> (opisanych w &sect;4 niniejszego Regulaminu), a także -
          ewentualnie &ndash; zawiera z Usługodawcą Umowę Usługi <strong>L&rsquo;emirGO</strong>
        </li>
        <li>
          18) <strong>Strona internetowa</strong> &ndash; strona internetowa prowadzona i
          administrowana przez Usługodawcę pod adresem (w domenie) www.lemir24.pl w ramach
          kt&oacute;rej prowadzona jest Platforma <strong>L&rsquo;emirGO</strong>;
        </li>
        <li>
          19) <strong>Umowa Najmu Pojazdu</strong> &ndash; umowa mająca za przedmiot najem od
          Usługodawcy Pojazdu Usługodawcy przez Użytkownika, zawarta za pośrednictwem środk&oacute;w
          porozumiewania się na odległość lub przy bezpośredniej obecności stron tej umowy;
          <strong>
            UWAGA: Umowa Najmu Pojazdu nie jest usługą świadczoną za pośrednictwem Portalu
            L&rsquo;emirGO i na warunkach określonych w Regulaminie
          </strong>
          ;
        </li>
        <li>
          20) <strong>Umowa Prac Serwisowych</strong> &ndash; odrębna umowa między Usługodawcą a
          Użytkownikiem, zawarta za pośrednictwem środk&oacute;w porozumiewania się na odległość lub
          przy bezpośredniej obecności stron tej umowy, po oszacowaniu i/lub uzgodnieniu między
          Użytkownikiem a Usługodawcą zakresu i koszt&oacute;w Prac serwisowych na Pojeździe
          Użytkownika;
          <strong>
            UWAGA: Umowa Prac Serwisowych nie jest usługą świadczoną za pośrednictwem Portalu
            L&rsquo;emirGO i na warunkach określonych w Regulaminie;
          </strong>
        </li>
        <li>
          21) <strong>Umowa Usługi L&rsquo;emirGO &ndash; </strong>nieodpłatna Umowa obejmująca
          możliwość złożenia przez Użytkownika kluczyka do Pojazdu Użytkownika w Skrytce w celu
          ustalenia zakresu i koszt&oacute;w Prac serwisowych na tym Pojeździe i otrzymania przez
          Użytkownika Kalkulacji;
        </li>
        <li>
          22) <strong>Usługa L&rsquo;emirGO &ndash; </strong>usługa obejmująca umożliwienie
          Użytkownikowi, po dokonaniu Rezerwacji, złożenia kluczyka do Pojazdu Użytkownika w Skrytce
          i pozostawienia Pojazdu Użytkownika w APS oraz otrzymania Kalkulacji, a także odbioru
          kluczyka do Pojazdu Użytkownika ze Skrytki, a tym samym odbioru Pojazdu Użytkownika z APS
        </li>
        <li>
          23)
          <strong>Usługa świadczona drogą elektroniczną (lub usługa elektroniczna) &ndash; </strong>
          zgodnie z ustawą z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (Dz.U. z
          2002 r. Nr 144, poz. 1204 z p&oacute;źn. zm.), usługa świadczona bez jednoczesnej
          obecności stron (na odległość), poprzez przekaz danych na indywidualne żądanie
          usługobiorcy (Użytkownika), przesyłana i otrzymywana za pomocą urządzeń do elektronicznego
          przetwarzania, włącznie z kompresją cyfrową, i przechowywania danych, kt&oacute;ra jest w
          całości nadawana, odbierana lub transmitowana za pomocą sieci telekomunikacyjnej w
          rozumieniu ustawy z dnia 16 lipca 2004 r. - Prawo telekomunikacyjne. Usługi świadczone
          drogą elektroniczną na podstawie nin. Regulaminu i za pośrednictwem Portalu
          <strong>L&rsquo;emirGO</strong> określa &sect;4 Regulaminu;
        </li>
      </ul>
      <h3>
        <strong>
          Zakres usług świadczonych drogą elektroniczną za pośrednictwem Portalu L&rsquo;emirGO i
          funkcjonalność Portalu L&rsquo;emirGO
        </strong>

        <strong>&sect; 4</strong>
      </h3>
      Za pośrednictwem Portalu <strong>L&rsquo;emirGO</strong> Usługodawca świadczy na rzecz
      Użytkownik&oacute;w następujące usługi elektroniczne:
      <ul>
        <li>
          1) Umożliwienie przeglądania treści zamieszczonych na Platformie
          <strong>L&rsquo;emirGO</strong>
        </li>
        <li>
          2) umożliwianie Użytkownikom dokonywania Rezerwacji oraz zawierania Umowy Usługi
          <strong>L&rsquo;emirGO</strong>.
        </li>
      </ul>
      <h3>
        <strong>
          Warunki świadczenia usług drogą elektroniczną (w tym wymagania techniczne) oraz zakaz
          dostarczania treści o charakterze bezprawnym
        </strong>
        <p>
          <strong>&sect;5</strong>
        </p>
      </h3>
      <ol>
        <li>
          Korzystanie z Platformy <strong>L&rsquo;emirGO</strong> wymaga spełnienia przez urządzenie
          końcowe i system teleinformatyczny, z kt&oacute;rego korzysta Użytkownik/ Reprezentant
          Użytkownika, wymagań technicznych wskazanych w ust. 2 (poniżej).
        </li>
        <li>
          Usługodawca podaje, że minimalne wymagania techniczne, kt&oacute;rych spełnienie jest
          niezbędne do wsp&oacute;łpracy z systemem teleinformatycznym, kt&oacute;rym posługuje się
          Usługodawca, w tym niezbędnych do dokonania Rezerwacji i zawarcia Umowy Usługi{' '}
          <strong>L&rsquo;emirGO</strong>, obejmują:
        </li>

        <ul>
          <li>
            1) komputer, laptop, telefon lub inne urządzenie multimedialne z dostępem do Internetu;
          </li>
          <li>2) dostęp do poczty elektronicznej;</li>
          <li>
            3) przeglądarka internetowa: Mozilla Firefox w wersji 17.0 i wyższej lub Internet
            Explorer w wersji 10.0 i wyższej, Opera w wersji 12.0 i wyższej, Google Chrome w wersji
            23.0. i wyższej, Safari w wersji 5.0 i wyższej;
          </li>
          <li>4) zalecana minimalna rozdzielczość ekranu: 1024x768;</li>
          <li>
            5) włączenie w przeglądarce internetowej możliwości zapisu plik&oacute;w Cookies oraz
            obsługi Javascript;
          </li>
          <li>
            6) <strong>z wykorzystaniem telefonu</strong>; system operacyjny urządzenia mobilnego:
            Android w wersji 4.0.3 lub wyższej lub iOS w wersji 9.0.4. By zawrzeć Umowę Usługi{' '}
            <strong>L&rsquo;emirGO</strong> musi posiadać aktywny adres e-mail, a także klawiaturę
            lub inne urządzenie, umożliwiające poprawne wypełnienie formularzy elektronicznych.
          </li>
        </ul>

        <li>
          Zabronione jest wykorzystywanie Platformy <strong>L&rsquo;emirGO</strong> w spos&oacute;b
          lub w celach sprzecznych z przepisami powszechnie obowiązującego prawa i niniejszego
          Regulaminu. Użytkownikom zabrania się zwłaszcza:
        </li>
      </ol>
      <ul>
        <li>
          1) zamieszczania, przesyłania, rozpowszechniania w jakiejkolwiek formie (np. zdjęcia,
          opinie, komentarze itp.) treści erotycznych lub pornograficznych, uwidaczniających nagość
          lub sugestywnych w zakresie odnoszącym się do w/w treści;
        </li>
        <li>2) treści naruszających polskie lub międzynarodowe przepisy prawa;</li>
        <li>3) treści powszechnie uważane za moralnie naganne;</li>
        <li>
          4) treści propagujących lub obrazujących lub demonstrujących lub zachęcających do
          nienawiści rasowej, religijnej, przemocy, dyskryminujących, przede wszystkim ze względu na
          pochodzenie rasowe lub religijne lub etniczne lub poglądy polityczne lub filozofię;
        </li>
        <li>
          5) naruszające dobra osobiste lub inne dobra albo prawa os&oacute;b trzecich, w tym przede
          wszystkim dobre imię, godność, wizerunek, tw&oacute;rczość naukową lub artystyczną;
        </li>
        <li>
          6) wykorzystywania Platformy <strong>L&rsquo;emirGO</strong> w celach komercyjnych,
          reklamowych Użytkownika lub os&oacute;b trzecich;
        </li>
        <li>
          7) zamieszczania treści zawierającej zwroty, słowa, postrzegane powszechnie za obraźliwe,
          wulgarne;
        </li>
        <li>
          8) działań mogących utrudniać lub zakł&oacute;cać działanie Platformy{' '}
          <strong>L&rsquo;emirGO</strong>, w tym w szczeg&oacute;lności wirus&oacute;w
          komputerowych;
        </li>
        <li>
          9) działań, w tym zamieszczania treści, mogących narazić Usługodawcę lub podmioty z nimi
          powiązane gospodarczo lub kapitałowo, albo osoby fizyczne, przede wszystkim u nich
          zatrudnione lub nimi zarządzające na utratę renomy lub dobrego imienia;
        </li>
        <li>
          10) innych działań sprzecznych z celem i zasadami funkcjonowania Platformy{' '}
          <strong>L&rsquo;emirGO</strong>.
        </li>
      </ul>
      <h3>
        <strong>
          Warunki oferowania i zawierania Umowy <br />
          Usługi L&rsquo;emirGO
        </strong>
        <p>
          <strong>&sect;6</strong>
        </p>
      </h3>
      <ol>
        <li>
          Usługodawca za pośrednictwem Platformy <strong>L&rsquo;emirGO</strong> oferuje i umożliwia
          Użytkownikom zawieranie &ndash; na podstawie postanowień niniejszego Regulaminu -
          Um&oacute;w Usługi <strong>L&rsquo;emirGO</strong> mających za przedmiot, i kt&oacute;rego
          gł&oacute;wną cechą świadczenia, jest umożliwienie Użytkownikowi, po dokonaniu Rezerwacji,
          złożenia kluczyka do Pojazdu Użytkownika w Skrytce i pozostawienia Pojazdu Użytkownika w
          APS, a także odbioru kluczyka do Pojazdu Użytkownika ze Skrytki, a tym samym odbioru
          Pojazdu Użytkownika z APS i otrzymania Kalkulacji o kt&oacute;rej mowa w ust. 3 poniżej
          oraz &sect;3 pkt 2 (Usługa <strong>L&rsquo;emirGO</strong>).
        </li>
        <li>
          Usługi wskazane w ust. 1 Usługodawca świadczy NIEODPŁATNIE na warunkach niniejszego
          Regulaminu.
        </li>
        <li>
          Umowa Usługi <strong>L&rsquo;emirGO</strong> oferowana jest w celu umożliwienia
          Usługodawcy uzgodnienia z Użytkownikiem /Reprezentantem Użytkownika zakresu i
          koszt&oacute;w Prac serwisowych na Pojeździe Użytkownika oraz przedstawienia Użytkownikowi
          wstępnego zakresu i Kalkulacji koszt&oacute;w Prac serwisowych.{' '}
          <u>
            <strong>
              WYŻEJ WYMIENIONA WSTĘPNY ZAKRES I KALKULACJA KOSZT&Oacute;W PRAC SERWISOWYCH (DALEJ
              KALKULACJA) NIE STANOWI OFERTY W ROZUMIENIU KODEKSU CYWILNEGO, LECZ ZAPROSZENIE DO
              ZAWARCIA UMOWY PRAC SERWISOWYCH I/LUB UMOWY NAJMU POJAZDU, O KT&Oacute;REJ MOWA W ART.
              71 KODEKSU CYWILNEGO.{' '}
            </strong>
          </u>
        </li>
        <li>
          Usługodawca umożliwia zawarcia Umowy Usługi <strong>L&rsquo;emirGO</strong> na warunkach
          niniejszego Regulaminu, poprzez dokonanie Rezerwacji. Czynności niezbędne do dokonania
          Rezerwacji określa &sect;7 niniejszego Regulaminu.{' '}
          <strong>
            DOKONUJĄC REZERWACJI UŻYTKOWNIK WYRAŻA ZGODĘ NA PRZYSTĄPIENIE DO WYKONANIA CZYNNOŚCI
            OKREŚLONYCH W UST. 3 PRZED UPŁYWEM TERMINU DO SKORZYSTANIA Z USTAWOWEGO PRAWA DO
            ODSTĄPIENIA OD UMOWY
          </strong>
          .
        </li>
        <li>
          Po dokonaniu Rezerwacji, o kt&oacute;rej mowa w &sect;7 (poniżej) i złożeniu kluczyka do
          Pojazdu Użytkownika oraz samego Pojazdu Użytkownika na terenie APS, najp&oacute;źniej w
          ciągu dw&oacute;ch dni roboczych przedstawiciel (pracownik) APS skontaktuje się z
          Użytkownikiem/ Reprezentantem Użytkownika, na numer telefonu lub adres email wskazany w
          trakcie Rezerwacji, celem ustalenia dokładnego zakresu oczekiwanych Prac serwisowych i
          przekazania Kalkulacji.
        </li>
        <li>
          <strong>
            ZAWIERAJĄC, POPRZEZ REZERWACJĘ, UMOWĘ USŁUGI L&rsquo;EMIRGO UŻYTKOWNIK / REPREZENTANT
            UŻYTKOWNIKA WYRAŻA ZGODĘ NA POBRANIE PRZEZ USŁUGODAWCĘ KLUCZYKA DO POJAZDU UŻYTKOWNIKA I
            WYKONANIA NA POJEŹDZIE UŻYTKOWNIKA W APS CZYNNOŚCI NIEZBĘDNYCH DO USTALENIA ZAKRESU PRAC
            SERWISOWYCH, W TYM R&Oacute;WNIEŻ ODBYCIA TZW. JAZDY TESTOWEJ PO DROGACH PUBLICZNYCH.
          </strong>
        </li>
        <li>
          Kalkulacja, o kt&oacute;rej mowa w ust. 3, zostanie przekazana po uzgodnieniu dokładnego
          zakresu tych prac między Usługodawcą i Użytkownikiem i przeprowadzeniu czynności
          określonych w ust. 6, w terminie dw&oacute;ch dni roboczych od dnia pozostawienia Pojazdu
          Użytkownika na terenie APS oraz kluczyka do tego Pojazdu w Skrytce.
        </li>
        <li>
          W przypadku niemożliwości wykonania w/w czynności we wskazanym w ust. 7 terminie
          Usługodawca zawiadomi o tym fakcie Użytkownika/ Reprezentanta Użytkownika wskazując
          pow&oacute;d niewykonania tych czynności i nowy termin ich wykonania. Powiadomienie
          nastąpi za pośrednictwem email lub telefonicznie na adres email/numer telefonu wskazany w
          trakcie Rezerwacji.
        </li>
        <li>
          <strong>
            Warunki zawarcia Umowy Prac Serwisowych i/lub Umowy Najmu Pojazdu, jak r&oacute;wnież
            zakres praw i obowiązk&oacute;w stron tych Um&oacute;w są uregulowane w odrębnych
            dokumentach. Umowa Prac Serwisowych i/lub Umowa Najmu Pojazdu, ich warunki, jak
            r&oacute;wnież spos&oacute;b ich zawierania między Użytkownikiem a Usługodawcą, nie są
            regulowane niniejszym Regulaminem.{' '}
          </strong>
        </li>
        <li>
          Po przekazaniu Kalkulacji, o kt&oacute;rej mowa w ust. 3 (powyżej) Usługodawca przekaże
          Użytkownikowi na adres email lub telefonicznie (na adres/numer wskazany w trakcie
          Rezerwacji), informację o możliwości odbioru Pojazdu Użytkownika i kluczyka do tego
          Pojazdu. Odbi&oacute;r Pojazdu Użytkownika i kluczyka do Pojazdu nastąpi za pośrednictwem
          Skrytki &ndash; lub na żądanie Użytkownika (złożone email lub telefonicznie) &ndash; przy
          bezpośredniej obecności stron.
        </li>
      </ol>
      Usługodawca prześle r&oacute;wnież na numer telefonu Użytkownika/Reprezentanta Użytkownika
      (podany w przy dokonywaniu Rezerwacji) wiadomość tekstową sms zawierająca kod QR i numer PIN,
      umożliwiająca odebranie kluczyka do Pojazdu Użytkownika ze Skrytki.
      <ol start="11">
        <li>
          Usługodawca podaje, że ze względ&oacute;w bezpieczeństwa, kod QR i numer PIN, przekazane
          zgodnie z ust. 10, wygasają po upływie określonego czasu od dnia ich przesłania
          wiadomością sms. O wygaśnięciu, przed odbiorem Pojazdu Użytkownika przez Użytkownika, kodu
          QR i numeru PIN, Usługodawca zawiadomi Użytkownika w spos&oacute;b jak opisany w ust. 10.
        </li>
        <li>
          Użytkownik jest zobowiązany do odbioru Pojazdu Użytkownika i kluczyka do tego Pojazdu
          najp&oacute;źniej w terminie 7dni od dnia otrzymania informacji (poprzez sms) o możliwości
          odbioru Pojazdu Użytkownika.
        </li>
        <li>
          UWAGA: Dokonując Rezerwacji Użytkownik /Reprezentant Użytkownika wyraża zgodę na kontakt z
          tym Użytkownikiem / Reprezentantem Użytkownika w celu umożliwienia Usługodawcy uzgodnienia
          z Użytkownikiem /Reprezentantem Użytkownika zakresu i koszt&oacute;w Prac serwisowych na
          Pojeździe Użytkownika oraz przedstawienia Użytkownikowi wstępnego zakresu i kalkulacji
          koszt&oacute;w Prac serwisowych (Kalkulacji).
        </li>
      </ol>
      <h3>
        <strong>Rezerwacja</strong>
        <p>
          <strong>&sect;7</strong>
        </p>
      </h3>
      <ol>
        <li>
          W celu dokonania Rezerwacji Użytkownik musi wypełnić formularz interaktywny
          (elektroniczny) zamieszczony w zakładce <strong>L&rsquo;emirGO</strong> (Platformie{' '}
          <strong>L&rsquo;emirGO</strong>) prowadzonej na Stronie internetowej.
        </li>
        <li>
          Minimalny zakres danych i informacji niezbędnych do dokonania Rezerwacji obejmuje podanie:
        </li>
        <ul>
          <li>
            1) Imienia i nazwiska osoby dokonującej Rezerwacji (Użytkownika/Reprezentanta
            Użytkownika), a w przypadku, gdy Rezerwacji dokonuje Reprezentant Użytkownika,
            r&oacute;wnież dane samego Użytkownika (imię i nazwisko lub nazwę/firmę Użytkownika, NIP
            Użytkownika &ndash; o ile jest on Przedsiębiorcą)
          </li>
          <li>
            2) numeru telefonu osoby dokonującej Rezerwacji tj. Użytkownika/Reprezentanta
            Użytkownika;
          </li>
          <li>
            3) adresu poczty elektronicznej osoby dokonującej Rezerwacji tj. Użytkownika
            /Reprezentanta Użytkownika;
          </li>
          <li>4) numeru rejestracyjnego Pojazdu Użytkownika</li>
          <li>
            5) dokonania wyboru przez Użytkownika /Reprezentanta Użytkownika daty dziennej (
            <strong>dalej data zdania</strong>), w kt&oacute;rej nastąpić ma pozostawienie Pojazdu
            Użytkownika i złożenie kluczyka do Skrytki. <br />
            <strong>
              UWAGA: data zdania nie może być przypadać wcześniej niż 1 godzina i jednocześnie
              p&oacute;źniej niż jeden miesiąc od momentu zatwierdzenia danych zgodnie z ust. 12
              (poniżej).
            </strong>
          </li>
        </ul>
        <li>
          Niezależnie od ust. 2 (powyżej) Użytkownik /Reprezentant Użytkownika w trakcie Rezerwacji
          i w ramach formularza interaktywnego (elektronicznego) składa poniższe oświadczenia:
        </li>
        <ul>
          <li>1) o akceptacji niniejszego Regulaminu;</li>
          <li>
            2) o zapoznaniu się z Klauzulą Informacyjną Ochrony Danych Osobowych oraz Polityką
            Prywatności
          </li>
          <li>
            3) że Użytkownik dysponuje tytułem prawnym do Pojazdu Użytkownika, kt&oacute;ry uprawnia
            go do zadysponowania tym Pojazdem w zakresie Umowy Usługi{' '}
            <strong>L&rsquo;emirGO</strong>;
          </li>
          <li>
            4) że podane przez niego przy składaniu rezerwacji dane osobowe, w tym numer telefonu,
            są prawdziwe
          </li>
          <li>
            5) że wyraża zgodę na niezwłoczne pobranie kluczyka do Pojazdu Użytkownika ze Skrytki i
            przystąpienia do wykonania przez Usługodawcę czynności niezbędnych do oszacowania
            niezbędnych Prac serwisowych w Pojeździe Użytkownika oraz ich koszt&oacute;w i
            przekazania Kalkulacji &ndash; względnie w wypadku wskazania przez Użytkownika zakresu
            prac &ndash; oszacowania ich wartości
          </li>
        </ul>
        <li>
          Poza informacjami i danymi określonymi w ust. 3 (powyżej) osoba dokonująca Rezerwacji
          (Użytkownik /Reprezentant Użytkownika) może podać dodatkowo zakres oczekiwanych przez
          niego Prac serwisowych na Pojeździe Użytkownika lub opis niesprawności/ żądany zakres prac
          dla tego Pojazdu.
        </li>
        <li>
          Usługodawca wskazuje, iż podanie danych określonych w ust. 3 jest dobrowolne, lecz ich
          niewskazanie może uniemożliwić skorzystanie z Usługi <strong>L&rsquo;emirGO</strong>.
          Informacje dotyczące ochrony danych osobowych i warunk&oacute;w ich przetwarzania określa
          &sect;15 i załącznik do Regulaminu.
        </li>
        <li>
          Po wprowadzeniu w formularz interaktywny (elektroniczny) danych i informacji określonych w
          ust. 3 &ndash; i ewentualnie ust. 4, Użytkownik/Reprezentant Użytkownika dokonuje
          zatwierdzenia danych poprzez naciśnięcie przycisku &bdquo;idź dalej&rdquo;.
        </li>
        <li>
          Po dokonaniu w ramach Rezerwacji czynności określonych w ust. 1-6 na wskazany przez
          Użytkownika /Reprezentanta Użytkownika adres email, przesłana zostanie przez Usługodawcę
          wiadomość zawierająca dane i informacje oraz oświadczenia złożone w toku Rezerwacji, w tym
          datę zdania Pojazdu Użytkownika, wraz z załączonym do niego Regulaminem, Pouczeniem o
          prawie odstąpienia oraz Klauzulą Informacyjną. W wiadomości email, o kt&oacute;rej mowa
          powyżej, zamieszczony będzie przycisk &bdquo;Potwierdzam Rezerwację&rdquo;.
        </li>
        <li>
          Użytkownik celem zawarcia Umowy Usługi <strong>L&rsquo;emirGO</strong> musi dokonać
          potwierdzenia Rezerwacji, poprzez przyciśnięcie przycisku &bdquo;Potwierdzam
          Rezerwację&rdquo; zamieszczonego w wiadomości email przekazanej zgodnie z ust. 11
          (powyżej).
        </li>
        <li>
          Umowa Usługi <strong>L&rsquo;emirGO</strong> zostaje zawarta z momentem potwierdzenia
          Rezerwacji zgodnie z ust. 7 i 8 (powyżej).
        </li>
        <li>
          Po dokonaniu potwierdzenia Rezerwacji, najp&oacute;źniej na 24h przed datą zdania Pojazdu
          Użytkownika na numer telefonu Użytkownika/Reprezentanta Użytkownika (podany w przy
          dokonywaniu Rezerwacji) przesłana zostanie wiadomość tekstowa sms zawierająca kod QR i
          numer PIN, umożliwiająca złożenie kluczyka do Pojazdu Użytkownika w Skrytce.
        </li>
        <li>
          Usługodawca podaje, że ze względ&oacute;w bezpieczeństwa, kod QR i numer PIN wygasają po
          upływie określonego czasu od dnia ich przesłania w spos&oacute;b opisany w ust. 10. O
          wygaśnięciu, przed datą zdania Pojazdu Użytkownika, kodu QR i numeru PIN Usługodawca
          zawiadomi Użytkownika w spos&oacute;b jak opisany w ust. 10.
        </li>
        <li>
          UWAGA: Dokonując Rezerwacji Użytkownik /Reprezentant Użytkownika wyraża zgodę na kontakt z
          tym Użytkownikiem / Reprezentantem Użytkownika w celu umożliwienia Usługodawcy uzgodnienia
          z Użytkownikiem /Reprezentantem Użytkownika zakresu i koszt&oacute;w Prac serwisowych na
          Pojeździe Użytkownika oraz przedstawienia Użytkownikowi wstępnego zakresu i kalkulacji
          koszt&oacute;w Prac serwisowych (Kalkulacji).
        </li>
      </ol>
      <h3>
        <strong>Bezpieczeństwo</strong>
        <p>
          <strong>&sect;8</strong>
        </p>
      </h3>
      <ol>
        <li>
          Usługodawca zapewnia środki techniczne i organizacyjne odpowiednie do stopnia zagrożenia
          bezpieczeństwa świadczonych funkcjonalności lub usług na podstawie umowy usługi
          elektronicznej. Korzystanie z usług elektronicznych wiąże się z typowymi zagrożeniami
          dotyczącymi przekazywania danych poprzez Internet oraz wiadomościami tekstowymi sms,
          takimi jak ich rozpowszechnienie, utrata lub uzyskiwanie do nich dostępu przez osoby
          nieuprawnione.
        </li>
        <li>
          Użytkownik zobowiązany jest zabezpieczyć kod QR i numer PIN otrzymany za pośrednictwem
          wiadomości tekstowej sms przed ujawnieniem osobom trzecim.
        </li>
        <li>
          Użytkownik ponosi całkowitą i wyłączną odpowiedzialność za wszelkie skutki wynikłe z
          korzystania z Portalu <strong>L&rsquo;emirGO</strong> przez osoby nieuprawnione,
          kt&oacute;re w jakikolwiek spos&oacute;b weszły w posiadanie kodu QR i numeru PIN
          Użytkownika bez winy Usługodawcy.
        </li>
        <li>
          W przypadku podejrzenia ujawnienia danych dostępowych (kodu QR i numeru PIN) Użytkownicy
          powinni niezwłocznie zawiadomić o tym fakcie Usługodawcę.
        </li>
      </ol>
      <h3>
        <p>
          <strong>Reklamacje usług elektronicznych</strong>
        </p>
        <p>
          <strong>(nie dotyczy reklamacji przedmiotu Umowy Usługi L&rsquo;emirGO)</strong>
        </p>
        <p>
          <strong>&sect;9</strong>
        </p>
      </h3>
      <ol>
        <li>
          Usługodawca podejmuje działania w celu zapewnienia w pełni poprawnego działania Portalu
          <strong>L&rsquo;emirGO</strong> w takim zakresie, jaki wynika z aktualnej wiedzy
          technicznej i zobowiązuje się usunąć w rozsądnym terminie wszelkie nieprawidłowości
          zgłoszone przez Użytkownika.
        </li>
        <li>
          Użytkownik powinien niezwłocznie powiadomić Usługodawcę dostrzeżonych nieprawidłowościach
          lub przerwach w funkcjonowaniu Platformy
          <strong> L&rsquo;emirGO.</strong>
        </li>
        <li>
          Reklamacje związane ze świadczeniem usług elektronicznych przez Użytkownika oraz pozostałe
          reklamacje związanie z działaniem Platformy <strong>L&rsquo;emirGO </strong>(z wyłączeniem
          procedury reklamacji dotyczącej usługi będącej przedmiotem Umowy Usługi{' '}
          <strong>L&rsquo;emirGO</strong>, tj. umowy zawartej za pośrednictwem Portalu{' '}
          <strong>L&rsquo;emirGO</strong>), Użytkownik może składać na adres L&rsquo;emir 41-300
          Dąbrowa G&oacute;rnicza ul. Sobieskiego 10a; lub w formie elektronicznej za pośrednictwem
          poczty elektronicznej na adres: serwis@lemir.com.pl;
        </li>
        <li>Zaleca się podanie przez Użytkownika w opisie reklamacji:</li>
        <ul>
          <li>
            1) informacji i okoliczności dotyczących przedmiotu reklamacji, w szczeg&oacute;lności
            rodzaju i daty wystąpienia nieprawidłowości;
          </li>
          <li>2) żądania Użytkownika; oraz</li>
          <li>
            3) danych kontaktowych składającego reklamację &ndash; ułatwi to i przyspieszy
            rozpatrzenie reklamacji przez Usługodawcę.
          </li>
        </ul>
        <li>
          Ustosunkowanie się do reklamacji przez Usługodawcę następuje nie p&oacute;źniej niż w
          terminie 14 dni kalendarzowych od dnia jej skutecznego złożenia. Usługodawca zobowiązuje
          się do rozpatrzenia każdej reklamacji w terminie do 14 dni, a gdyby to nie było możliwe,
          do poinformowania w tym okresie Użytkownika, kiedy reklamacja zostanie rozpatrzona.
        </li>
      </ol>
      <h3>
        <strong>Reklamacje dotyczące Umowy Usługi L&rsquo;emiGO</strong>
        <p>
          <strong>&sect;10</strong>
        </p>
      </h3>
      <ol>
        <li>
          Podstawa i zakres odpowiedzialności Usługodawcy z tytułu niewykonania lub nienależytego
          wykonania Umowy Usługi <strong>L&rsquo;emirGO</strong> regulują przepisy ustawy Kodeks
          Cywilny. Powyższe nie dotyczy Użytkownik&oacute;w nie będących Konsumentami, dla
          kt&oacute;rych zakres odpowiedzialności Usługodawcy określono w &sect;16 niniejszego
          Regulaminu.
        </li>
        <li>
          Usługodawca zobowiązany jest do wykonania Umowy Usługi <strong>L&rsquo;emirGO</strong> z
          należytą starannością.
        </li>
        <li>Reklamacja może zostać złożona przez Użytkownika przykładowo:</li>
        <ul>
          <li>
            1) pisemnie na adres: L&rsquo;emir 41-300 Dąbrowa G&oacute;rnicza ul. Sobieskiego 10a
          </li>
          <li>2) Email na adres: serwis@lemir.com.pl</li>
        </ul>
        <li>
          Ustosunkowanie się do reklamacji przez Usługodawcę następuje nie p&oacute;źniej niż w
          terminie 14 dni kalendarzowych od dnia jej skutecznego złożenia. Usługodawca zobowiązuje
          się do rozpatrzenia reklamacji w terminie do 14 dni, a gdyby to nie było możliwe, do
          poinformowania w tym okresie Użytkownika, kiedy reklamacja zostanie rozpatrzona.
        </li>
      </ol>
      &nbsp;
      <h3>
        <strong>
          Rozwiązanie umowy o świadczenie usług elektronicznych (nie dotyczy rozwiązania Usługi
          L&rsquo;emirGO) i wstrzymanie dostępu do Portalu L&rsquo;emirGO, zmiany Regulaminu{' '}
        </strong>
        <p>
          <strong>&sect;11</strong>
        </p>
      </h3>
      <ol>
        <li>
          Usługodawca zastrzega sobie możliwość rozwiązania umowy o świadczenie usług drogą
          elektroniczną, w tym zaprzestania świadczenia tych usług w każdym czasie.
        </li>
        <li>
          Użytkownik może zaprzestać korzystania z Portalu <strong>L&rsquo;emirGO</strong> do
          momentu zatwierdzenia danych podanych w formularzu interaktywnym (elektronicznym) w
          trakcie Rezerwacji, zgodnie &sect;7 ust. 6, w tym poprzez opuszczenie (wyjście z) Portalu{' '}
          <strong>L&rsquo;emirGO</strong>.
        </li>
        <li>
          R&oacute;wnież po zatwierdzeniu danych, zgodnie z &sect;7 ust.6 Użytkownik może nie
          dokonywać Potwierdzenia Rezerwacji zgodnie z &sect;7 ust. 8.
        </li>
        <li>
          W sytuacjach opisanych w ust. 1 i 2 nie dochodzi do zawarcia Umowy Usługi{' '}
          <strong>L&rsquo;emirGO</strong>, jak r&oacute;wnież następuje rozwiązanie umowy o
          świadczenie usług elektronicznych.
        </li>
        <li>
          Rozwiązanie umowy o świadczenie usług elektronicznych nie rodzi żadnych koszt&oacute;w po
          stronie Użytkownika.
        </li>
        <li>
          Usługodawca może pozbawić Użytkownika, kt&oacute;ry dokonał Rezerwacji, praw do
          korzystania z Portalu <strong>L&rsquo;emirGO</strong>, jak r&oacute;wnież może ograniczyć
          jego dostęp do części lub całości zasob&oacute;w Portalu <strong>L&rsquo;emirGO</strong> -
          ze skutkiem natychmiastowym - poprzez przesłanie na adres email podany w trakcie
          Rezerwacji, w przypadku naruszenia postanowień Regulaminu:
        </li>
        <ul>
          <li>
            1) Użytkownik/ Reprezentant Użytkownika podał w trakcie Rezerwacji dane i informacje
            określone w &sect;6 ust. 7 pkt 1-3) niezgodne z prawdą, wprowadzające w błąd Usługodawcę
            lub naruszające prawa os&oacute;b trzecich.
          </li>
          <li>2) Użytkownik naruszył obowiązki określone w &sect;5 ust. 3 Regulaminu</li>
        </ul>
        <li>
          Usługodawca jest uprawniony do przerw lub zakł&oacute;ceń w świadczeniu usług drogą
          elektroniczną i udostępnianiu Portalu <strong>L&rsquo;emirGO</strong> jeśli powodem jest:
        </li>
        <ul>
          <li>
            1) modyfikacja, modernizacja, rozbudowa lub konserwacja systemu teleinformatycznego lub
            oprogramowania Usługodawcy
          </li>
          <li>
            2) siła wyższa, działania lub zaniechania os&oacute;b trzecich (działania niezależne od
            Usługodawcy)
          </li>
        </ul>
        <li>
          Usługodawca zastrzega sobie prawo do zmiany lub zaprzestania udostępniania Platformy{' '}
          <strong>L&rsquo;emirGO</strong>, rezygnacji ze świadczenia usług elektronicznych lub
          wprowadzania zmian do tych usług, a także wprowadzania nowych serwis&oacute;w
          internetowych lub usług elektronicznych, w każdym czasie.
        </li>
        <li>
          Usługodawca może informować użytkownik&oacute;w Portalu <strong>L&rsquo;emirGO</strong> o
          zmianach lub zaprzestaniu udostępniania serwis&oacute;w internetowych lub usług
          elektronicznych w szczeg&oacute;lności poprzez zamieszczenie stosownego oświadczenia
          Usługodawcy na stronach internetowych Portalu <strong>L&rsquo;emirGO</strong> lub Stronie
          internetowej na kt&oacute;rej prowadzony jest Portal <strong>L&rsquo;emirGO</strong>.
        </li>
        <li>
          Usługodawca zastrzega sobie r&oacute;wnież prawo do zmiany Regulaminu w każdym czasie. O
          zmianie Regulaminu Usługodawca zawiadomi Użytkownik&oacute;w w spos&oacute;b opisany w
          ust. 9 z 7 dniowym wyprzedzeniem. Zmiany Regulaminu wchodzą w życie po upływie o w/w
          terminu. Zmiana Regulaminu nie ma wpływu na treść Umowy Usługi{' '}
          <strong>L&rsquo;emirGO</strong> zawartych wg warunk&oacute;w Regulaminu obowiązującego w
          chwili ich zawierania.
        </li>
      </ol>
      <h3>
        <strong>Rozwiązanie Umowy Usługi L&rsquo;emirGO</strong>
        <p>
          <strong>&sect;12</strong>
        </p>
      </h3>
      <ol>
        <li>
          Użytkownik, kt&oacute;ry zawarł Umowę Usługi <strong>L&rsquo;emirGO</strong>, może
          rozwiązać tą umowę do czasu jej wykonania przez Usługodawcę (tj. do czasu przekazania
          Użytkownikowi Kalkulacji o kt&oacute;rej mowa w &sect;6 ust. 3 Regulaminu, bez ponoszenia
          z tego tytułu jakichkolwiek koszt&oacute;w.
        </li>
        <li>
          Rozwiązanie Umowy Usługi <strong>L&rsquo;emirGO</strong> następuje r&oacute;wnież poprzez
          niezłożenie w dacie zdania (&sect;7 ust. 2 pkt 5 Regulaminu), kluczyk&oacute;w do Pojazdu
          Użytkownika i niepozostawienia Pojazdu Użytkownika w APS.
        </li>
        <li>
          Po zdaniu kluczyka do Pojazdu Użytkownika Użytkownik może złożyć oświadczenie o
          rozwiązaniu Umowy Usługi <strong>L&rsquo;emirGO</strong> na adres email Usługodawcy
          serwis@lemir.com.pl. Rozwiązanie Umowy Usługi <strong>L&rsquo;emirGO</strong> po złożeniu
          kluczyka do Pojazdu Użytkownika do Skrytki następuje z 7 dniowym wypowiedzeniem.
        </li>
        <li>
          W sytuacji, o kt&oacute;rej mowa powyżej, Usługodawca &ndash; w terminie 7 od dnia
          otrzymania oświadczenia o rozwiązaniu umowy - prześle Użytkownikowi informację o dacie
          odbioru przez użytkownika Pojazdu Użytkownika, jak r&oacute;wnież kod QR i numer PIN.
        </li>
        <li>
          Kod QR i numer PIN zostanie wysłany Użytkownikowi przez Usługodawcę na numer telefonu
          Użytkownika/Reprezentanta Użytkownika (podany w przy dokonywaniu Rezerwacji).
        </li>
        <li>
          Usługodawca podaje, że ze względ&oacute;w bezpieczeństwa, kod QR i numer PIN wygasają po
          upływie określonego czasu od dnia ich przesłania w spos&oacute;b opisany w ust. 4. O
          wygaśnięciu, kodu QR i numeru PIN Usługodawca zawiadomi Użytkownika w spos&oacute;b jak
          opisany w ust. 4, a także &ndash; w ten sam spos&oacute;b &ndash; przekaże Użytkownikowi
          nowy kod QR i numer PIN.
        </li>
        <li>
          Odbi&oacute;r Pojazdu Użytkownika możliwy jest r&oacute;wnież, w wypadku złożenia takiego
          żądania przez Użytkownika, przy bezpośredniej obecności stron.
        </li>
        <li>
          Mając na uwadze cel na jaki zdany został Pojazd Użytkownika i kluczyk do tego pojazdu,
          Usługodawca zapewni możliwość odbioru kluczyka do Pojazdu Użytkownika oraz samego Pojazdu
          Użytkownika nie p&oacute;źniej niż do upływu okresu wypowiedzenia określonego w ust. 3
          (powyżej).
        </li>
      </ol>
      <h3>
        <strong>Ustawowe prawo odstąpienia od umowy przez konsumenta</strong>
        <p>
          <strong>&sect;13</strong>
        </p>
      </h3>
      <ol>
        <li>
          Konsument, kt&oacute;ry zawarł umowę na odległość, może odstąpić od niej bez podawania
          przyczyny i bez ponoszenia jakichkolwiek koszt&oacute;w innych niż przewidziane przez
          przepisy prawa, w terminie 14 dni od dnia zawarcia umowy (dalej r&oacute;wnież
          &bdquo;Ustawowe prawo odstąpienia&rdquo;).
        </li>
        <li>
          Do zachowania terminu wystarczy wysłanie przez Użytkownika będącego Konsumentem
          oświadczenia przed upływem terminu do odstąpienia od umowy wskazanego w ust. 1.
        </li>
        <li>
          Konsument może złożyć jakiekolwiek jednoznaczne oświadczenie, w kt&oacute;rym poinformuje
          o swoim odstąpieniu od Umowy Usługi <strong>L&rsquo;emirGO</strong>. Oświadczenie o
          odstąpieniu od Umowy <strong>L&rsquo;emirGO</strong> może zostać złożone np.:
        </li>
        <ul>
          <li>
            1) pisemnie na adres: L&apos;emir Sp. z o.o., 41-300 Dąbrowa G&oacute;rnicza ul.
            Sobieskiego 10a
          </li>
          <li>
            2) w formie elektronicznej za pośrednictwem poczty elektronicznej na adres:
            <u>serwis@lemir.com.pl</u>
          </li>
          <li>
            3) z wykorzystaniem formularza odstąpienia, kt&oacute;ry stanowi załącznik do
            niniejszego Regulaminu.
          </li>
        </ul>
        <li>
          Konsument może skorzystać z wzoru formularza odstąpienia, o kt&oacute;rym mowa w ust. 3
          pkt 3 powyżej jednak nie jest to obowiązkowe.
        </li>
        <li>
          W przypadku odstąpienia od umowy zawartej na odległość umowę uważa się za niezawartą.
        </li>
        <li>
          Prawo odstąpienia od umowy zawartej na odległość, o kt&oacute;rym mowa w ust. 1 (powyżej),
          nie przysługuje Konsumentowi w przypadkach wskazanych w art. 38 ustawy z dnia 30 maja 2014
          r. o prawach konsumenta, w szczeg&oacute;lności w odniesieniu do um&oacute;w:
        </li>
        <ul>
          <li>
            1) świadczenie usług, jeżeli przedsiębiorca wykonał w pełni usługę za wyraźną zgodą
            konsumenta, kt&oacute;ry został poinformowany przed rozpoczęciem świadczenia, że po
            spełnieniu świadczenia przez przedsiębiorcę utraci prawo odstąpienia od umowy;
          </li>
          <li>
            2) w kt&oacute;rej cena lub wynagrodzenie zależy od wahań na rynku finansowym, nad
            kt&oacute;rymi przedsiębiorca nie sprawuje kontroli, i kt&oacute;re mogą wystąpić przed
            upływem terminu do odstąpienia od umowy;
          </li>
          <li>
            3) w kt&oacute;rej przedmiotem świadczenia jest rzecz nieprefabrykowana, wyprodukowana
            według specyfikacji konsumenta lub służąca zaspokojeniu jego zindywidualizowanych
            potrzeb;
          </li>
          <li>
            4) w kt&oacute;rej przedmiotem świadczenia jest rzecz ulegająca szybkiemu zepsuciu lub
            mająca kr&oacute;tki termin przydatności do użycia;
          </li>
          <li>
            5) w kt&oacute;rej przedmiotem świadczenia jest rzecz dostarczana w zapieczętowanym
            opakowaniu, kt&oacute;rej po otwarciu opakowania nie można zwr&oacute;cić ze względu na
            ochronę zdrowia lub ze względ&oacute;w higienicznych, jeżeli opakowanie zostało otwarte
            po dostarczeniu;
          </li>
          <li>
            6) w kt&oacute;rej przedmiotem świadczenia są rzeczy, kt&oacute;re po dostarczeniu, ze
            względu na sw&oacute;j charakter, zostają nierozłącznie połączone z innymi rzeczami;
          </li>
          <li>
            7) w kt&oacute;rej przedmiotem świadczenia są napoje alkoholowe, kt&oacute;rych cena
            została uzgodniona przy zawarciu umowy sprzedaży, a kt&oacute;rych dostarczenie może
            nastąpić dopiero po upływie 30 dni i kt&oacute;rych wartość zależy od wahań na rynku,
            nad kt&oacute;rymi przedsiębiorca nie ma kontroli;
          </li>
          <li>
            8) w kt&oacute;rej konsument wyraźnie żądał, aby przedsiębiorca do niego przyjechał w
            celu dokonania pilnej naprawy lub konserwacji; jeżeli przedsiębiorca świadczy dodatkowo
            inne usługi niż te, kt&oacute;rych wykonania konsument żądał, lub dostarcza rzeczy inne
            niż części zamienne niezbędne do wykonania naprawy lub konserwacji, prawo odstąpienia od
            umowy przysługuje konsumentowi w odniesieniu do dodatkowych usług lub rzeczy;
          </li>
          <li>
            9) w kt&oacute;rej przedmiotem świadczenia są nagrania dźwiękowe lub wizualne albo
            programy komputerowe dostarczane w zapieczętowanym opakowaniu, jeżeli opakowanie zostało
            otwarte po dostarczeniu;
          </li>
          <li>
            10) o dostarczanie dziennik&oacute;w, periodyk&oacute;w lub czasopism, z wyjątkiem umowy
            o prenumeratę;
          </li>
          <li>11) zawartej w drodze aukcji publicznej;</li>
          <li>
            12) o świadczenie usług w zakresie zakwaterowania, innych niż do cel&oacute;w
            mieszkalnych, przewozu rzeczy, najmu samochod&oacute;w, gastronomii, usług związanych z
            wypoczynkiem, wydarzeniami rozrywkowymi, sportowymi lub kulturalnymi, jeżeli w umowie
            oznaczono dzień lub okres świadczenia usługi;
          </li>
          <li>
            13) o dostarczanie treści cyfrowych, kt&oacute;re nie są zapisane na nośniku
            materialnym, jeżeli spełnianie świadczenia rozpoczęło się za wyraźną zgodą konsumenta
            przed upływem terminu do odstąpienia od umowy i po poinformowaniu go przez
            przedsiębiorcę o utracie prawa odstąpienia od umowy.
          </li>
        </ul>
        <li>
          Zgodnie z art. 37 ustawy z dnia 30 maja 2014 r. o prawach konsumenta, w chwili odstąpienia
          przez Konsumenta od umowy zawartej na odległość lub poza lokalem przedsiębiorstwa wygasają
          powiązane z nią umowy dodatkowe zawarte przez Konsumenta, jeżeli na ich podstawie
          świadczenie jest spełniane przez przedsiębiorcę lub osobę trzecią na podstawie
          porozumienia z przedsiębiorcą. Konsument nie ponosi koszt&oacute;w związanych z
          wygaśnięciem tych um&oacute;w, z wyjątkiem przewidzianych w przepisach wyżej wymienionej
          ustawy.
        </li>
        <li>
          Usługodawca nie pobiera żadnych płatności w związku z Usługą
          <strong>L&rsquo;emirGO</strong>.
        </li>
        <li>
          Usługodawca informuje, że zawierając Umowę Usługi <strong>L&rsquo;emirGO</strong>
          Użytkownik wyraża zgodę na przystąpienie do wykonania czynności obejmujących ocenę stanu
          Pojazdu Użytkownika w celu przygotowania Kalkulacji przed upływem terminu do skorzystania
          z ustawowego prawa do odstąpienia od umowy (&sect;7 ust. 3 pkt 5 Regulaminu).
        </li>
      </ol>
      <h3>
        <strong>Pozasądowe sposoby rozpatrywania reklamacji i dochodzenia roszczeń</strong>
        <p>
          <strong>&sect;14</strong>
        </p>
      </h3>
      <ol>
        <li>
          Szczeg&oacute;łowe informacje dotyczące możliwości skorzystania przez Użytkownika będącego
          Konsumentem z pozasądowych sposob&oacute;w rozpatrywania reklamacji i dochodzenia roszczeń
          oraz zasady dostępu do tych procedur dostępne są na stronie internetowej Urzędu Ochrony
          Konkurencji i Konsument&oacute;w pod adresem: http://polubowne.uokik.gov.pl/
        </li>
        <li>
          Użytkownikowi będącemu Konsumentem przysługują następujące przykładowe możliwości
          pozasądowego rozpatrywania spor&oacute;w i dochodzenia roszczeń:
        </li>
        <ul>
          <li>1) wniosek o rozstrzygnięcie sporu do stałego polubownego sądu konsumenckiego</li>
          <li>
            2) wniosek w sprawie pozasądowego rozwiązania sporu do wojew&oacute;dzkiego inspektora
            Inspekcji Handlowej (więcej informacji na stronie inspektora właściwego ze względu na
            miejsce wykonywania działalności gospodarczej przez Sprzedawcę); oraz
          </li>
          <li>
            3) wystąpienie o pomoc do powiatowego (miejskiego) rzecznika konsument&oacute;w lub
            organizacji społecznej, do kt&oacute;rej zadań statutowych należy ochrona
            konsument&oacute;w Usługodawca podaje, że dostępna jest r&oacute;wnież platforma
            internetowego systemu rozstrzygania spor&oacute;w pomiędzy konsumentami i
            przedsiębiorcami na szczeblu unijnym (platforma ODR). Wyżej wymieniona platforma ODR
            stanowi interaktywną i wielojęzyczną stronę internetową z punktem kompleksowej obsługi
            dla konsument&oacute;w i przedsiębiorc&oacute;w dążących do pozasądowego rozstrzygnięcia
            sporu dotyczącego zobowiązań umownych wynikających z internetowej umowy sprzedaży lub
            umowy o świadczenie usług. Platforma zamieszczona jest pod adresem pod adresem
            http://ec.europa.eu/consumers/odr
          </li>
        </ul>
        <li>
          Usługodawca informuje, że przy Prezesie Urzędu Ochrony Konkurencji i Konsument&oacute;w
          działa punkt kontaktowy. Dane punktu kontaktowego można odnaleźć na stronie UOKIK
          http://polubowne.uokik.gov.pl/kontakt,7,pl.html (telefon: 22 55 60 333, email:
          kontakt.adr@uokik.gov.pl lub adres pisemny: Pl. Powstańc&oacute;w Warszawy 1, 00-950
          Warszawa). Powyższy punkt kontaktowy służy w szczeg&oacute;lności udzielaniem między
          innymi udzielanie pomocy konsumentom w sprawach dotyczących pozasądowego rozwiązywania
          spor&oacute;w konsumenckich.
        </li>
      </ol>
      <h3>
        <strong>Ochrona danych osobowych</strong>
        <p>
          <strong>&sect;15</strong>
        </p>
      </h3>
      <ol>
        <li>
          Administratorem danych osobowych Użytkownik&oacute;w jest L&apos;emir Sp. z o.o., ul.
          Sobieskiego 16, 41-300 Dąbrowa G&oacute;rnicza, NIP: 629-000-76-04. REGON 271074824,
          wpisana do rejestru przedsiębiorc&oacute;w prowadzonego przez Sąd Rejonowy
          Katowice-Wsch&oacute;d w Katowicach Wydział VII Gospodarczy KRS nr 0000956642.
        </li>
        <li>
          Administrator dokłada starań, aby odpowiednio zabezpieczyć dane osobowe i w spos&oacute;b
          zrozumiały przedstawić spos&oacute;b korzystania przez niego z tych danych.
        </li>
        <li>
          Administrator przetwarza dane osobowe zgodnie z przepisami prawa powszechnie
          obowiązującego, w tym w szczeg&oacute;lności Rozporządzenia Parlamentu Europejskiego i
          Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony os&oacute;b fizycznych w
          związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych
          oraz uchylenia dyrektywy 95/46/WE (og&oacute;lne rozporządzenie o ochronie danych) (RODO)
          oraz ustawy z dnia 10 maja 2018 r. o ochronie danych osobowych.
        </li>
        <li>
          Szczeg&oacute;łowe informacje służące wskazaniu Użytkownikom i/lub Reprezentantom
          Użytkownik&oacute;w zasad przetwarzania danych osobowych podanych przez tych
          Użytkownik&oacute;w i/lub Reprezentant&oacute;w Użytkownik&oacute;w w trakcie Rezerwacji,
          określa Klauzula Informacyjna stanowiąca załącznik do niniejszego Regulaminu.
        </li>
      </ol>
      <h3>
        <strong>Postanowienia dotyczące klient&oacute;w nie będących konsumentami</strong>
        <p>
          <strong>&sect;16</strong>
        </p>
      </h3>
      <ol>
        <li>
          Niniejszy paragraf Regulaminu dotyczy wyłącznie Użytkownik&oacute;w niebędących
          Konsumentami.
        </li>
        <li>
          Usługodawca może rozwiązać umowę o świadczenie usług lub Umowę Usługi{' '}
          <strong>L&rsquo;emirGO</strong> z Użytkownikiem nie będącym Konsumentem w każdym czasie i
          żądać odbioru kluczyka do Pojazdu Użytkownika oraz odebrania Pojazdu Użytkownika z APS. W
          przypadku nieodebrania Pojazdu Użytkownika mimo rozwiązania Umowy Usługi{' '}
          <strong>L&rsquo;emirGO</strong> w terminie 7 od dnia rozwiązania tej Umowy, L&rsquo;emir
          ma prawo naliczyć opłatę za post&oacute;j Pojazdu Użytkownika na terenie APS i/lub
          korzystanie ze Skrytki w wysokości 100 zł za każdy dzień op&oacute;źnienia w odbiorze
          Pojazdu Użytkownika i/lub odbioru kluczyka ze Skrytki.
        </li>
        <li>
          Oświadczenie o rozwiązaniu Umowy Usługi <strong>L&rsquo;emirGO</strong> może nastąpić na
          adres email lub poprzez wiadomość tekstową sms przesłaną Użytkownikowi/ Reprezentantowi
          Użytkownika wskazane w trakcie Rezerwacji.
        </li>
        <li>
          Rozwiązanie umowy o świadczenie usług elektronicznych lub Umowy Usługi{' '}
          <strong>L&rsquo;emirGO</strong> nie wymaga wskazania przyczyny lub podania uzasadnienia.
        </li>
        <li>
          W każdym przypadku ustalenia odpowiedzialności Usługodawcy, jego pracownik&oacute;w,
          upoważnionych przedstawicieli i/lub pełnomocnik&oacute;w, odpowiedzialność ta w stosunku
          do Użytkownika niebędącego Konsumentem, bez względu na jej podstawę prawną, jest
          ograniczona - zar&oacute;wno w ramach pojedynczego roszczenia, jak r&oacute;wnież za
          wszelkie roszczenia w sumie - do wysokości 1000 zł.
        </li>
        <li>
          Wyłącza się jednocześnie odpowiedzialność Usługodawcy wobec Użytkownika nie będącego
          Konsumentem za utracone korzyści. Powyższe ograniczenia odpowiedzialności (ust. 5 i 6
          powyżej) nie mają zastosowania w przypadku, gdy szkoda powstała z winy umyślnej
          Usługodawcy lub os&oacute;b wskazanych w ust. 5
        </li>
        <li>
          Wszelkie spory powstałe pomiędzy Usługodawcą a Użytkownikiem niebędącym Konsumentem
          zostają poddane sądowi właściwemu ze względu na siedzibę Usługodawcy.
        </li>
        <li>
          W odniesieniu do Użytkownik&oacute;w niebędących Konsumentami Sprzedawca może dokonać
          zmiany Regulaminu w każdym czasie na podstawie powszechnie obowiązujących przepis&oacute;w
          prawa, jak r&oacute;wnież może odstąpić od Umowy Usługi <strong>L&rsquo;emirGO</strong> do
          momentu przedstawienia Kalkulacji, o kt&oacute;rej mowa w &sect;6 ust. 3 Regulaminu.
        </li>
        <li>
          W przypadku Użytkownik&oacute;w niebędących Konsumentami, Usługodawca ponosi
          odpowiedzialność za zdany mu Pojazd Użytkownika i kluczyki do tego Pojazdu wyłącznie w
          sytuacji gdy szkoda w tym Pojeździe powstała z wyłącznej winy Usługodawcy. Wyłącza się
          odpowiedzialność Usługodawcy za szkody powstałe na skutek kradzieży, zniszczenia Pojazdu
          Użytkownika przez osoby trzecie (nie będące pracownikami, wsp&oacute;łpracownikami lub
          innymi osobami, za kt&oacute;re odpowiedzialność ponosi Usługodawca), jak r&oacute;wnież
          na skutek siły wyższej. Użytkownik nie będący Konsumentem przyjmuje do wiadomości, że
          Pojazd Użytkownika zostaje przez niego zdany na terenie APS, kt&oacute;ry nie jest
          strzeżony i ogrodzony, a tym samym przyjmuje na siebie ryzyko utraty tego Pojazdu
          Użytkownika na skutek kradzieży lub jego uszkodzenia przez osoby trzecie. Podobnie,
          Użytkownik, o kt&oacute;rym mowa powyżej, przyjmuje do wiadomości, że kluczyk do Pojazdu
          Użytkownika jest przez niego deponowany w skrytce wyposażonej w zamek elektroniczny, lecz
          posadowionej na zewnątrz budynku APS, ryzyko to akceptując.
        </li>
        <li>
          Niniejszy paragraf Regulaminu, nie stosuje się do Użytkownik&oacute;w będących osobami
          fizycznymi prowadzącymi działalności gospodarczą, kt&oacute;re zawierają z Usługodawcą
          Umowę Usługi <strong>L&rsquo;emirGO</strong>, umowę o świadczenie usług na podstawie
          Regulaminu bezpośrednio związaną z ich działalnością gospodarczą, gdy z treści określonej
          umowy wynika, że nie posiada ona dla Użytkownika charakteru zawodowego, wynikającego w
          szczeg&oacute;lności z przedmiotu wykonywanej przez niego działalności gospodarczej,
          określonego na podstawie przepis&oacute;w o Centralnej Ewidencji i Informacji o
          Działalności Gospodarczej. Użytkownikom tym przysługuje r&oacute;wnież uprawnienie do
          odstąpienia od umowy na zasadach przewidzianych w &sect;13 Regulaminu.
        </li>
        <li>
          W przypadku rozbieżności między pozostałymi paragrafami Regulaminu a niniejszym
          paragrafem, w zakresie Um&oacute;w Usługi L&rsquo;emir i usług świadczonych drogą
          elektroniczną zawartych między Usługodawcą a Użytkownikami nie będącymi Konsumentami,
          pierwszeństwo uzyskują postanowienia niniejszego paragrafu.
        </li>
      </ol>
      <h3>
        <strong>Postanowienia końcowe</strong>
        <p>
          <strong>&sect;17</strong>
        </p>
      </h3>
      <ol>
        <li>Regulamin obowiązuje od dnia 1.02.2021.</li>
        <li>
          Umowy zawierane przez Usługodawcę za pośrednictwem Portalu
          <strong>L&rsquo;emirGO</strong> (w tym umowa o świadczenie usług elektronicznych oraz
          Umowa Usługi <strong>L&rsquo;emirGO</strong>) zawierane są w języku polskim i podlegają
          prawu polskiemu
        </li>
        <li>
          Wyb&oacute;r prawa polskiego na podstawie niniejszego Regulaminu nie pozbawia Konsumenta
          ochrony przyznanej mu na podstawie przepis&oacute;w, kt&oacute;rych nie można wyłączyć w
          drodze umowy pomiędzy Usługodawcą a Konsumentem, na mocy prawa, kt&oacute;re zgodnie z
          właściwymi regulacjami byłoby właściwe w przypadku braku wyboru.
        </li>
        <li>
          Korzystanie z poszczeg&oacute;lnych, dalszych, usług świadczonych drogą elektroniczną, a
          nie objętych niniejszym Regulaminem (&sect;4), jak i nie dotyczące Umowy Usługi{' '}
          <strong>L&rsquo;emirGO</strong>, może odbywać się w oparciu o uregulowania
          szczeg&oacute;lne, w szczeg&oacute;lności odpowiednie regulaminy, cenniki, noty
          zawierające dane i informacje odnośnie przedmiotu, zakresu i parametr&oacute;w tych usług.
        </li>
        <li>
          Użytkownik informowany jest i zapoznaje się z uregulowaniami szczeg&oacute;lnymi
          wymienionymi ust. 4 odrębnie, ale w spos&oacute;b odpowiadający właściwym przepisom prawa.
        </li>
        <li>Załączniki do niniejszego Regulaminu stanowią:</li>
      </ol>
      - Klauzula Informacyjna (dot. ochrony danych osobowych) - Formularz odstąpienia od Umowy
    </div>
  </>
);

export default TermsOfService;
