/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './menu-link.module.scss';

const MenuLink = ({ closeMenu }) => {
  const { t } = useTranslation();

  return (
    <ul className={styles.list}>
      <li className={styles.list__item} onClick={closeMenu}>
        <a href="/#modal" className={styles.list__itemLink}>
          {t('navigationLinkFirst')}
        </a>
      </li>
      <li className={styles.list__item} onClick={closeMenu}>
        <a href="/#about" className={styles.list__itemLink}>
          {t('navigationLinkSecond')}
        </a>
      </li>
      <li className={styles.list__item} onClick={closeMenu}>
        <a href="/#contact" className={styles.list__itemLink}>
          {t('navigationLinkThird')}
        </a>
      </li>
      {/* <li className={styles.list__item} onClick={closeMenu}>
        <a href="/uslugi" className={styles.list__itemLink}>
          {t('navigationLinkFourth')}
        </a>
      </li> */}
    </ul>
  );
};

export default MenuLink;
