import React from 'react';
import { connect } from 'react-redux';

import styled from 'styled-components';
import Stage from './components/Stage/stage';
import Form from './components/Form/form';
import Verification from './components/Verification/verification';
import Rent from './components/Rent/rent';
import Confirmation from './components/Confirmation/confirmation';
import Term from './components/Term/term';

const Wrapper = styled.div`
  @keyframes slideIn {
    from {
      max-width: 820px;
    }

    to {
      max-width: 100%;
    }
  }
  margin: 0px auto;
  background: hsla(0, 0%, 0%, 0.9);
  color: var(--color-white);
  border: 1px solid rgba(0, 0, 0, 1);
  border-radius: 10px;
  padding: 55px 10px;
  animation-duration: 0.5s;
  animation-name: slideIn;
  position: relative;

  @media screen and (min-width: 490px) {
    margin: 20px auto 20px auto;
    padding: 55px 40px;
  }
`;

const Booking = (props) => {
  const { openForm, verification, rent, confirmation, term } = props;

  return (
    <Wrapper>
      {term ? (
        <Term />
      ) : (
        <>
          <Stage />
          {openForm && <Form />}
          {verification && <Verification />}
          {rent && <Rent />}
          {confirmation && <Confirmation />}{' '}
        </>
      )}
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  openForm: state.UI.openForm,
  verification: state.UI.verification,
  rent: state.UI.rent,
  confirmation: state.UI.confirmation,
  term: state.UI.term,
});

export default connect(mapStateToProps)(Booking);
