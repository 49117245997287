import React from 'react';

import styles from './button.module.scss';

const Button = ({ children, onPress, secondary }) => (
  <button
    className={secondary ? styles.buttonSecondary : styles.button}
    type="button"
    onClick={onPress}
  >
    {children}
  </button>
);

export default Button;
