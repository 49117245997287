import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import {
  toggleShowRent,
  toggleShowVerification,
  toggleShowConfirmation,
} from 'redux/Actions/ui-actions';

import styles from './rent.module.scss';
import ArrowLeft from '../../../../assets/arrow-left.svg';
import ArrowRight from '../../../../assets/arrow-right.svg';
import Button from '../../../Button/button';

const Rent = (props) => {
  const {
    showRentFUNC,
    showVerificationFUNC,
    showConfirmationFUNC,
    phoneNumber,
    arrivalDate,
    name,
    registrationNumbers,
    street,
    houseNumber,
    city,
    postCode,
    description,
  } = props;

  const { t } = useTranslation();

  const onSubmitReservation = () => {
    const url = 'https://api.bergregions.pl/api/v1/deposit/reservation/touch';

    axios
      .post(url, {
        phoneNumber,
        arrivalDate,
        name,
        registrationNumbers,
        street,
        houseNumber,
        city,
        postCode,
        description,
      })
      .then((response) => response.data())
      .catch(() => {
        // handle error
      });
  };

  const handleBackView = () => {
    showRentFUNC();
    showVerificationFUNC();
  };

  const handleNextStep = () => {
    onSubmitReservation();
    showRentFUNC();
    showConfirmationFUNC();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.rent}>
      <header>
        <h2 className={styles.rent__title}>{t('rentTitle')}</h2>
        <h2 className={styles.rent__title}>{t('rentTitle2')}</h2>
      </header>

      <div className={styles.rent__box}>
        <div>
          <p className={styles.rent__paragraph}>{t('rentParagraph')}</p>
          <p className={styles.rent__paragraphBold}>{t('rentParagraphSecond')}</p>
        </div>
        {/* <a
          className={styles.rent__button}
          href="https://lemirgo.pl/uslugi"
          target="_blank"
          rel="noreferrer"
        >
          {t('buttonShowOffer')}
        </a> */}

        <div className={styles.rent__container}>
          <div
            className={styles.rent__boxPhoto}
            style={{
              background: `url('${process.env.PUBLIC_URL}/img/proces-obslugi-klienta-6.png')`,
              backgroundSize: 'cover',
              backgroundPositionX: -70,
            }}
          />
          <div
            className={styles.rent__boxPhotoMain}
            style={{
              background: `url('${process.env.PUBLIC_URL}/img/proces-obslugi-klienta-10.png')`,
              backgroundSize: 'cover',
              backgroundPositionX: -70,
            }}
          />
          <div
            className={styles.rent__boxPhoto}
            style={{
              background: `url('${process.env.PUBLIC_URL}/img/serwisowe-14.png')`,
              backgroundSize: 'cover',
              backgroundPositionX: -70,
            }}
          />
        </div>
      </div>

      <div className={styles.buttons}>
        <Button secondary onPress={handleBackView}>
          <img className={styles.buttons__arrowLeft} src={ArrowLeft} alt="Strzałka w lewo" />
          {t('formButtonBack')}
        </Button>
        <Button onPress={handleNextStep}>
          {t('formButtonForward')}
          <img className={styles.buttons__arrowRight} src={ArrowRight} alt="Strzałka w prawo" />
        </Button>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  showVerificationFUNC: () => dispatch(toggleShowVerification()),
  showRentFUNC: () => dispatch(toggleShowRent()),
  showConfirmationFUNC: () => dispatch(toggleShowConfirmation()),
});

const mapStateToProps = (state) => ({
  phoneNumber: state.Form.phoneNumber,
  arrivalDate: state.Form.arrivalDate,
  name: state.Form.name,
  registrationNumbers: state.Form.registrationNumbers,
  street: state.Form.street,
  houseNumber: state.Form.houseNumber,
  city: state.Form.city,
  postCode: state.Form.postCode,
  description: state.Form.description,
});

export default connect(mapStateToProps, mapDispatchToProps)(Rent);
