import React from 'react';

import styles from './button-showMore.module.scss';
import ArrowDown from '../../../../assets/arrow-down.svg';

const ButtonShowMore = ({ children, onPress, secondary }) => (
  <button
    className={secondary ? styles.buttonSecondary : styles.button}
    type="button"
    onClick={onPress}
  >
    {children}
    <img src={ArrowDown} alt="Strzałka w dół" />
  </button>
);

export default ButtonShowMore;
