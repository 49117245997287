import { combineReducers } from 'redux';

import uiReducer from './Reducers/ui-reducer';
import formReducer from './Reducers/form-reducer';

const rootReducer = combineReducers({
  UI: uiReducer,
  Form: formReducer,
});

export default rootReducer;
