/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {
  toggleShowMoreForm,
  toggleOpenForm,
  toggleShowVerification,
  toggleOpenBooking,
  toggleShowTerm,
} from 'redux/Actions/ui-actions';
import {
  toggleAddPhone,
  toggleAddArrivalDate,
  toggleAddName,
  toggleAddRegistrationNumber,
  toggleAddStreet,
  toggleAddHouseNumber,
  toggleAddCity,
  toggleAddPostCode,
  toggleAddDescription,
  toggleCheckedTerm,
} from 'redux/Actions/form-actions';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import DatePicker, { registerLocale } from 'react-datepicker';
import pl from 'date-fns/locale/pl';
import './react-datepicker.css';

import dayjs from 'dayjs';
import styles from './form.module.scss';
import ArrowLeft from '../../../../assets/arrow-left.svg';
import ArrowRight from '../../../../assets/arrow-right.svg';
import Button from '../../../Button/button';
import ButtonShowMore from '../Button/button-showMore';
import CheckboxBird from '../../../../assets/checkbox-bird.svg';
import Flag from '../../../../assets/flag-poland.svg';

registerLocale('pl', pl);

const Form = (props) => {
  const {
    showMoreFormFUNC,
    openFormFUNC,
    showVerificationFUNC,
    openBookingFUNC,
    showTermFUNC,
    checkedTermFUNC,
    addPhoneFUNC,
    addArrivalDateFUNC,
    addNameFUNC,
    addRegistrationNumberFUNC,
    addStreetFUNC,
    addHouseNumberFUNC,
    addCityFUNC,
    addPostCodeFUNC,
    addDescriptionFUNC,
    showMoreForm,
    phoneNumber,
    arrivalDate,
    name,
    registrationNumbers,
    street,
    houseNumber,
    city,
    postCode,
    description,
    checkedTerm,
  } = props;

  const { t } = useTranslation();
  const minDate = dayjs().format('YYYY-MM-DD');
  const [startDate, setStartDate] = useState(new Date());
  const [dateError, setDateError] = useState(false);
  const [boxError, setBoxError] = useState(false);

  const { register, handleSubmit, errors } = useForm();
  const onSubmitVerification = () => {
    if (minDate <= arrivalDate) {
      axios
        .post('https://api.bergregions.pl/api/v1/deposit/reservation/touch/send-verification', {
          phoneNumber,
        })
        .then((response) => response.data())
        // eslint-disable-next-line no-unused-vars
        .catch(() => {
          // handle error
        });

      openFormFUNC();
      showVerificationFUNC();
    } else {
      setDateError(true);
    }
  };

  const handleClickToCheck = () => {
    checkedTermFUNC();
    setBoxError(false);
  };

  const handleBoxError = () => {
    setBoxError(true);
  };

  const handleReadTerm = () => {
    showTermFUNC();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (minDate <= arrivalDate) {
      setDateError(false);
    } else {
      setDateError(true);
    }
  }, [dateError]);

  return (
    <form className={styles.form}>
      <section className={styles.form__scroll}>
        <div className={styles.form__section}>
          <div className={styles.form__field}>
            <label className={styles.form__fieldLabel} htmlFor="phoneNumber">
              {t('formInputLabelPhone')}
            </label>
            <div className={errors.phoneNumber ? styles.inputError : styles.input}>
              <img className={styles.input__flag} src={Flag} alt="Flaga Polski" />
              +48
              <input
                className={styles.input__phone}
                name="phoneNumber"
                id="phoneNumber"
                type="tel"
                autoComplete="off"
                value={phoneNumber
                  .replace(/[^\dA-Z]/g, '')
                  .replace(/(.{3})/g, '$1 ')
                  .trim()}
                onChange={addPhoneFUNC}
                maxLength="11"
                ref={register({ required: true, minLength: 11 })}
              />
            </div>
            <span className={errors.phoneNumber ? styles.form__errorActive : styles.form__error}>
              {t('formErrorPhone')}
            </span>
          </div>
          <div className={styles.form__field}>
            <label className={styles.form__fieldLabel} htmlFor="arrivalDate">
              {t('formInputLabelDate')}
            </label>
            <DatePicker
              locale="pl"
              className={dateError ? styles.form__dateError : styles.form__date}
              dateFormat="dd-MM-yyyy"
              selected={startDate}
              defaultValue={addArrivalDateFUNC(startDate)}
              onChange={(date) => {
                setStartDate(date);
                addArrivalDateFUNC(date);
                setDateError(!dateError);
              }}
            />
            <span className={dateError ? styles.form__errorActive : styles.form__error}>
              {t('formErrorDate')}
            </span>
          </div>
        </div>

        {showMoreForm ? (
          <ButtonShowMore secondary onPress={() => showMoreFormFUNC()}>
            {t('formButtonShowMore')}
          </ButtonShowMore>
        ) : (
          <ButtonShowMore onPress={() => showMoreFormFUNC()}>
            {t('formButtonShowMore')}
          </ButtonShowMore>
        )}

        {showMoreForm && (
          <div className={styles.form__showMore}>
            <div className={styles.form__section}>
              <div className={styles.form__field}>
                <label className={styles.form__fieldLabel} htmlFor="name">
                  {t('formInputLabelName')}
                </label>
                <input
                  className={styles.form__fieldInput}
                  name="name"
                  id="name"
                  value={name}
                  onChange={addNameFUNC}
                  maxLength="30"
                  ref={register({ required: false })}
                />
                {errors.name && <span className={styles.form__error}>{t('formError')}</span>}
              </div>
              <div className={styles.form__field}>
                <label className={styles.form__fieldLabel} htmlFor="registrationNumbers">
                  {t('formInputLabelCarNumber')}
                </label>
                <input
                  className={styles.form__fieldInputSmall}
                  name="registrationNumbers"
                  id="registrationNumbers"
                  value={registrationNumbers}
                  onChange={addRegistrationNumberFUNC}
                  maxLength="10"
                  ref={register({ required: false })}
                />
                {errors.registrationNumbers && (
                  <span className={styles.form__error}>{t('formError')}</span>
                )}
              </div>
            </div>
            <header>
              <h2 className={styles.form__title}>{t('formAddressTitle')}</h2>
            </header>
            <div className={styles.form__section}>
              <div className={styles.form__field}>
                <label className={styles.form__fieldLabel} htmlFor="street">
                  {t('formInputLabelStreet')}
                </label>
                <input
                  className={styles.form__fieldInput}
                  name="street"
                  id="street"
                  value={street}
                  onChange={addStreetFUNC}
                  maxLength="30"
                  ref={register({ required: false })}
                />
                {errors.street && <span className={styles.form__error}>{t('formError')}</span>}
              </div>
              <div className={styles.form__field}>
                <label className={styles.form__fieldLabel} htmlFor="houseNumber">
                  {t('formInputLabelHouseNumber')}
                </label>
                <input
                  className={styles.form__fieldInputSmall}
                  name="houseNumber"
                  id="houseNumber"
                  value={houseNumber}
                  onChange={addHouseNumberFUNC}
                  maxLength="10"
                  ref={register({ required: false })}
                />
                {errors.houseNumber && <span className={styles.form__error}>{t('formError')}</span>}
              </div>
            </div>
            <div className={styles.form__section}>
              <div className={styles.form__field}>
                <label className={styles.form__fieldLabel} htmlFor="city">
                  {t('formInputLabelCity')}
                </label>
                <input
                  className={styles.form__fieldInput}
                  name="city"
                  id="city"
                  value={city}
                  onChange={addCityFUNC}
                  maxLength="20"
                  ref={register({ required: false })}
                />
                {errors.city && <span className={styles.form__error}>{t('formError')}</span>}
              </div>
              <div className={styles.form__field}>
                <label className={styles.form__fieldLabel} htmlFor="postCode">
                  {t('formInputLabelZipCode')}
                </label>
                <input
                  className={styles.form__fieldInputSmall}
                  name="postCode"
                  id="postCode"
                  value={postCode}
                  onChange={addPostCodeFUNC}
                  maxLength="6"
                  ref={register({ required: false })}
                />
                {errors.postCode && <span className={styles.form__error}>{t('formError')}</span>}
              </div>
            </div>
            <div className={styles.form__section}>
              <div className={styles.form__field}>
                <label className={styles.form__fieldLabel} htmlFor="description">
                  {t('formInputLabelMessage')}
                </label>
                <textarea
                  className={styles.form__fieldInputTextarea}
                  name="description"
                  id="description"
                  value={description}
                  onChange={addDescriptionFUNC}
                  maxLength="280"
                  ref={register({ required: false })}
                />
                {errors.description && <span className={styles.form__error}>{t('formError')}</span>}
              </div>
            </div>
          </div>
        )}
      </section>

      <div className={styles.term}>
        {checkedTerm ? (
          <button className={styles.term__button} type="button" onClick={handleClickToCheck}>
            <img className={styles.term__birdVisible} src={CheckboxBird} alt="Checkbox bird" />
          </button>
        ) : (
          <button className={styles.term__button} type="button" onClick={handleClickToCheck}>
            <img className={styles.term__bird} src={CheckboxBird} alt="Checkbox bird" />
          </button>
        )}
        <p className={styles.term__paragraph}>
          {t('formCheckBoxTerm1/3')}&nbsp;
          <span className={styles.term__buttonParagraph} onClick={handleReadTerm}>
            {t('formCheckBoxTerm2/3')}
          </span>
          &nbsp;{t('formCheckBoxTerm3/3')}
        </p>
      </div>
      {boxError ? (
        <span className={styles.form__errorActive}>{t('formCheckBoxTermError')}</span>
      ) : (
        <span className={styles.form__error}>{t('formCheckBoxTermError')}</span>
      )}

      <div className={styles.buttons}>
        <Button secondary onPress={() => openBookingFUNC()}>
          <img className={styles.buttons__arrowLeft} src={ArrowLeft} alt="Strzałka w lewo" />
          {t('formButtonBack')}
        </Button>
        {checkedTerm ? (
          <Button type="submit" onPress={handleSubmit(onSubmitVerification)}>
            {t('formButtonForward')}
            <img className={styles.buttons__arrowRight} src={ArrowRight} alt="Strzałka w prawo" />
          </Button>
        ) : (
          <Button type="submit" onPress={handleBoxError}>
            {t('formButtonForward')}
            <img className={styles.buttons__arrowRight} src={ArrowRight} alt="Strzałka w prawo" />
          </Button>
        )}
      </div>
    </form>
  );
};

const mapDispatchToProps = (dispatch) => ({
  showMoreFormFUNC: () => dispatch(toggleShowMoreForm()),
  openFormFUNC: () => dispatch(toggleOpenForm()),
  openBookingFUNC: () => dispatch(toggleOpenBooking()),
  showVerificationFUNC: () => dispatch(toggleShowVerification()),
  showTermFUNC: () => dispatch(toggleShowTerm()),
  checkedTermFUNC: () => dispatch(toggleCheckedTerm()),
  addPhoneFUNC: (evt) => dispatch(toggleAddPhone(evt)),
  addArrivalDateFUNC: (date) => dispatch(toggleAddArrivalDate(date)),
  addNameFUNC: (evt) => dispatch(toggleAddName(evt)),
  addRegistrationNumberFUNC: (evt) => dispatch(toggleAddRegistrationNumber(evt)),
  addStreetFUNC: (evt) => dispatch(toggleAddStreet(evt)),
  addHouseNumberFUNC: (evt) => dispatch(toggleAddHouseNumber(evt)),
  addCityFUNC: (evt) => dispatch(toggleAddCity(evt)),
  addPostCodeFUNC: (evt) => dispatch(toggleAddPostCode(evt)),
  addDescriptionFUNC: (evt) => dispatch(toggleAddDescription(evt)),
});

const mapStateToProps = (state) => ({
  showMoreForm: state.UI.showMoreForm,
  phoneNumber: state.Form.phoneNumber,
  arrivalDate: state.Form.arrivalDate,
  name: state.Form.name,
  registrationNumbers: state.Form.registrationNumbers,
  street: state.Form.street,
  houseNumber: state.Form.houseNumber,
  city: state.Form.city,
  postCode: state.Form.postCode,
  description: state.Form.description,
  checkedTerm: state.Form.checkedTerm,
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
