import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

const QR = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  min-width: 100vw;
  min-height: 100vh;

  img {
    max-width: 90%;
  }
`;

const QrView = () => {
  const { code } = useParams();
  const [imageQr, setImageKodQr] = useState('');

  useEffect(() => {
    const url = `https://api.bergregions.pl/api/v1/deposit/reservation/qr/${code}`;

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.text())
      .then((response) => {
        // handle success
        setImageKodQr(response);
      })
      .catch(() => {
        // handle error
      });
  }, []);

  return (
    <QR>
      <img src={`${imageQr}`} alt="Kod QR" />
    </QR>
  );
};

export default QrView;
