import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import styles from './footer.module.scss';
import Instagram from '../../assets/instagram_icon-white.svg';
import Facebook from '../../assets/facebook_icon-white.svg';
import Email from '../../assets/email_icon-white.svg';
import Berg from '../../assets/logo/logoBR.png';

const Footer = ({ handleShowRegulations, handleShowPrivacy, handleShowRodo }) => {
  const { t } = useTranslation();

  const [logo, setLogo] = useState([]);

  useEffect(() => {
    axios({
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      url: 'https://api.bergregions.pl/api/v1/tv-app/preview/29307792',
    })
      .then((response) => {
        // handle success
        setLogo(response.data.logo.sizes.find((o) => o.size === 'thumbnail').url);
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error) => {
        // handle error
      });
  }, []);

  return (
    <footer className={styles.footer}>
      <section className={styles.footer__wrapper}>
        <header className={styles.logo}>
          <div>
            <img className={styles.logo__lemir} src={logo} alt="Logo L'emir" title="L'EMIR" />
            <p className={styles.footer__title}>{t('footerTitle')}</p>
            <p className={styles.footer__paragraph}>{t('footerParagraph')}</p>
            <p className={styles.footer__paragraph}>{t('footerParagraphSecond')}</p>
          </div>
          <div className={styles.media}>
            <a
              className={styles.link__item}
              href="https://www.instagram.com/skodalemir/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className={styles.media__logo}
                src={Instagram}
                alt="Logo Instagram"
                title="Link do Instagram"
              />
            </a>
            <a
              className={styles.link__item}
              href="https://www.facebook.com/lemirskoda/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className={styles.media__logo}
                src={Facebook}
                alt="Logo Facebook"
                title="Link do Facebook"
              />
            </a>
            <a className={styles.link__item} href="mailto:salon@lemir.com.pl">
              <img className={styles.media__logo} src={Email} alt="Logo Email" title="E-mail" />
            </a>
          </div>
        </header>

        <div className={styles.link}>
          <button type="button" className={styles.link__item} onClick={handleShowRegulations}>
            {t('footerTermFirst')}
          </button>
          <button type="button" className={styles.link__item} onClick={handleShowPrivacy}>
            {t('footerTermSecond')}
          </button>
          <button type="button" className={styles.link__item} onClick={handleShowRodo}>
            {t('footerTermThird')}
          </button>
        </div>
        <p className={styles.footer__copyright}>{t('footerTermCopyright')}</p>
        <div className={styles.berg}>
          <img className={styles.berg__logo} src={Berg} alt="Logo Berg Regions" />
          <p className={styles.berg__content}>
            Powered by{' '}
            <a
              className={styles.berg__link}
              href="http://bergregions.pl/"
              target="_blank"
              rel="noreferrer"
            >
              BergRegions
            </a>
          </p>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
