import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './modal-start.module.scss';
import Button from '../Button/button';

const ModalStart = ({ openBooking }) => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.modal} id="modal">
      <div className={styles.modal__innerWrapper}>
        <div
          className={styles.modal__photo}
          style={{
            background: `url('${process.env.PUBLIC_URL}/img/proces-obslugi-klienta-10.png')`,
            backgroundSize: 'cover',
            backgroundPositionX: -110,
          }}
        />
        <p className={styles.modal__paragraph}>{t('modalStartParagraph')}</p>
        <Button onPress={() => openBooking()}>{t('modalStartButton')}</Button>
      </div>
    </div>
  );
};

export default ModalStart;
