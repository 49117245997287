import * as actionTypes from '../Types/form-types';

export const toggleAddPhone = (evt) => ({
  type: actionTypes.ADD_PHONE_NUMBER,
  payload: evt.target.value,
});

export const toggleAddArrivalDate = (date) => ({
  type: actionTypes.ADD_ARRIVAL_DATE,
  payload: date,
});

export const toggleAddName = (evt) => ({
  type: actionTypes.ADD_NAME,
  payload: evt.target.value,
});

export const toggleAddRegistrationNumber = (evt) => ({
  type: actionTypes.ADD_REGISTRATION_NUMBER,
  payload: evt.target.value,
});

export const toggleAddStreet = (evt) => ({
  type: actionTypes.ADD_STREET,
  payload: evt.target.value,
});

export const toggleAddHouseNumber = (evt) => ({
  type: actionTypes.ADD_HOUSE_NUMBER,
  payload: evt.target.value,
});

export const toggleAddCity = (evt) => ({
  type: actionTypes.ADD_CITY,
  payload: evt.target.value,
});

export const toggleAddPostCode = (evt) => ({
  type: actionTypes.ADD_POST_CODE,
  payload: evt.target.value,
});

export const toggleAddDescription = (evt) => ({
  type: actionTypes.ADD_DESCRIPTION,
  payload: evt.target.value,
});

export const toggleAddPinCode = (evt) => ({
  type: actionTypes.ADD_PIN_CODE,
  payload: evt.target.value,
});

export const toggleAddStatusVerification = (data) => ({
  type: actionTypes.ADD_STATUS_VERIFICATION,
  payload: data,
});

export const toggleCheckedTerm = () => ({
  type: actionTypes.CHECKED__TERM,
});

export const toggleAcceptTerm = () => ({
  type: actionTypes.ACCEPT__TERM,
});

export const toggleResetData = () => ({
  type: actionTypes.RESET_DATA,
});
