import * as actionTypes from '../Types/ui-types';

const INITIAL_STATE = {
  booking: false,
  openForm: true,
  showMoreForm: false,
  verification: false,
  rent: false,
  confirmation: false,
  term: false,
};

const uiReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.OPEN_BOOKING:
      return {
        ...state,
        openBooking: !state.openBooking,
      };
    case actionTypes.OPEN_FORM:
      return {
        ...state,
        openForm: !state.openForm,
      };
    case actionTypes.SHOW_MORE_FORM:
      return {
        ...state,
        showMoreForm: !state.showMoreForm,
      };
    case actionTypes.SHOW_VERIFICATION:
      return {
        ...state,
        verification: !state.verification,
      };
    case actionTypes.SHOW_RENT:
      return {
        ...state,
        rent: !state.rent,
      };
    case actionTypes.SHOW_CONFIRMATION:
      return {
        ...state,
        confirmation: !state.confirmation,
      };
    case actionTypes.SHOW_TERM:
      return {
        ...state,
        term: !state.term,
      };
    default:
      return state;
  }
};

export default uiReducer;
