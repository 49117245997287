import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './map.module.scss';
import PhoneIcon from '../../assets/phone_icon.svg';
import EmailIcon from '../../assets/email_icon.svg';
import Instagram from '../../assets/instagram_icon.svg';
import Facebook from '../../assets/facebook_icon.svg';
import NavPoint from '../../assets/nav_point.svg';
import Mapbox from './mapbox';

const Map = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <header id="contact">
        <h2 className={styles.map__title}>{t('mapTitle')}</h2>
      </header>
      <div className={styles.map}>
        <div className={styles.map__description}>
          <div className={styles.description}>
            <div>
              <p className={styles.description__paragraph}>{t('mapServiceTitle')}</p>
              <a className={styles.description__phone} href="tel:32-508-80-00">
                32 50 88 000
              </a>
              <p className={styles.description__paragraphNormal}>
                {t('mapServiceHours')} <br />
                {t('mapServiceHoursSaturday')}
              </p>
            </div>
            <a className={styles.description__phone} href="tel:32-508-80-00">
              <img className={styles.map__phoneIcon} src={PhoneIcon} alt="Ikona telefonu" />
            </a>
          </div>
          <div className={styles.description}>
            <div>
              <p className={styles.description__paragraphMargin}>{t('mapSalonTitle')}</p>
              <a className={styles.description__phone} href="tel:32-508-80-00">
                32 50 88 000
              </a>
              <p className={styles.description__paragraphNormal}>{t('mapSalonHours')}</p>
            </div>
            <a className={styles.description__phone} href="tel:32-508-80-00">
              <img className={styles.map__phoneIcon} src={PhoneIcon} alt="Ikona telefonu" />
            </a>
          </div>
          <div className={styles.description}>
            <div>
              <p className={styles.description__paragraphNormalMargin}>{t('mapEmail')}</p>
              <p className={styles.description__paragraphDecoration}>
                <a className={styles.description__phoneMail} href="mailto:salon@lemir.com.pl">
                  salon@lemir.com.pl
                </a>
              </p>
            </div>
            <a className={styles.description__phoneMail} href="mailto:salon@lemir.com.pl">
              <div className={styles.map__email}>
                <img className={styles.map__emailIcon} src={EmailIcon} alt="Ikona email" />
              </div>
            </a>
          </div>
          <div>
            <div>
              <p className={styles.description__paragraphMargin}>Social media:</p>
            </div>
            <div className={styles.description__mediaBox}>
              <a
                className={styles.description__link}
                href="https://www.instagram.com/skodalemir/"
                target="_blank"
                rel="noreferrer"
                title="Link do Instagram"
              >
                <img className={styles.description__socialIcon} src={Instagram} alt="Ikona email" />
              </a>
              <a
                className={styles.description__link}
                href="https://www.facebook.com/lemirskoda/"
                target="_blank"
                rel="noreferrer"
                title="Link do Facebook"
              >
                <img className={styles.description__socialIcon} src={Facebook} alt="Ikona email" />
              </a>
            </div>
            <div
              className={styles.description__qr}
              style={{
                background: `url('${process.env.PUBLIC_URL}/img/qr-code.svg')`,
                backgroundSize: 'cover',
              }}
            />
          </div>
        </div>
        <div className={styles.map__map}>
          <a
            className={styles.btn}
            href="https://www.google.com/maps/place/Skoda+L'emir/@50.3254262,19.169484,17z/data=!3m1!4b1!4m5!3m4!1s0x4716da11357b33b3:0x345e85ee8fa62b3a!8m2!3d50.3253948!4d19.1716738"
            target="_blank"
            rel="noreferrer"
          >
            <div className={styles.nav}>
              <div>
                <p style={{ fontWeight: 'bold' }}>{t('name')}</p>
                <p>{t('address')}</p>
              </div>
              <img className={styles.nav__point} src={NavPoint} alt="Punkt nawigacyjny" />
            </div>
          </a>
          <Mapbox />
        </div>
      </div>
    </div>
  );
};

export default Map;
