import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import Footer from 'components/Footer/footer';
import styles from './OfferView.module.scss';
import Regulations from '../../components/Regulations/regulations';

const OfferView = ({ background, backgroundMedium }) => {
  const { t } = useTranslation();
  const [showRegulations, setShowRegulations] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showRodo, setShowRodo] = useState(false);

  const handleShowRegulations = () => {
    setShowRegulations(!showRegulations);
  };
  const handleShowPrivacy = () => {
    setShowPrivacy(!showPrivacy);
  };
  const handleShowRodo = () => {
    setShowRodo(!showRodo);
  };
  const handleCloseBoxRegulation = () => {
    setShowRegulations(false);
    setShowPrivacy(false);
    setShowRodo(false);
  };

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://widget.droplabs.pl/widget.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <picture>
        <source media="(min-width:996px)" srcSet={`${background}`} />
        <img className={styles.background} src={backgroundMedium} alt="Tło" />
      </picture>
      <div className={styles.offer}>
        <h2 className={styles.offer__title}>{t('offerTitle')}</h2>
        <iframe
          id="dl-widget"
          title="droplabs"
          className={styles.offer__iframe}
          src="https://widget.droplabs.pl/index.html?facilityId=1177&onlineGroupId=13367"
        />
      </div>

      {showRegulations && (
        <Regulations
          handleShowRegulations={handleShowRegulations}
          handleCloseBoxRegulation={handleCloseBoxRegulation}
        />
      )}
      {showPrivacy && (
        <Regulations
          handleShowPrivacy={handleShowPrivacy}
          handleCloseBoxRegulation={handleCloseBoxRegulation}
        />
      )}
      {showRodo && (
        <Regulations
          handleShowRodo={handleShowRodo}
          handleCloseBoxRegulation={handleCloseBoxRegulation}
        />
      )}
      <Footer
        handleShowRegulations={handleShowRegulations}
        handleShowPrivacy={handleShowPrivacy}
        handleShowRodo={handleShowRodo}
      />
    </>
  );
};

export default OfferView;
