import React, { useState } from 'react';
import ReactMapGL, { Marker } from 'react-map-gl';

import './mapbox.scss';

const MapBox = () => {
  const [viewport, setViewport] = useState({
    latitude: 50.3253948,
    longitude: 19.1716738,
    zoom: 17,
  });

  return (
    <ReactMapGL
      className="map"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...viewport}
      onViewportChange={(nextViewport) => setViewport(nextViewport)}
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      mapStyle="mapbox://styles/bergregions2020/cksk7m3cf0mn417qvbmq7hy1g"
      width="100%"
      height="100%"
      maxZoom={18}
      minZoom={13}
      pitch={60}
    >
      <Marker offsetTop={-80} offsetLeft={-20} latitude={50.3256948} longitude={19.1714999}>
        {viewport.zoom > 15 && (
          <img
            className="map__point"
            src={`${process.env.PUBLIC_URL}/img/point_map.svg`}
            alt="Punkt"
          />
        )}
      </Marker>
    </ReactMapGL>
  );
};

export default MapBox;
