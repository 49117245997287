import React, { useState } from 'react';

import './navigation.scss';
import MenuLink from './menu-link';

const Navigation = () => {
  const [showMenu, setShowMenu] = useState(false);

  const handleOpenMenu = () => {
    setShowMenu(!showMenu);
  };

  const styles = {
    bar1: {
      transform: showMenu && 'rotate(-45deg) translate(-7px, 9px)',
    },
    bar2: {
      opacity: showMenu && 0,
    },
    bar3: {
      transform: showMenu && 'rotate(45deg) translate(-5px, -7px)',
    },
  };

  return (
    <nav>
      {showMenu ? (
        <>
          <div className="navigation__open">
            <MenuLink closeMenu={handleOpenMenu} handleOpenMenu={handleOpenMenu} />
          </div>
          <button className="navigation__buttonFixed" type="button" onClick={handleOpenMenu}>
            <div className="navigation__bar" style={styles.bar1} />
            <div className="navigation__bar" style={styles.bar2} />
            <div className="navigation__bar" style={styles.bar3} />
          </button>
        </>
      ) : (
        <>
          <div className="navigation__openClose">
            <MenuLink />
          </div>
          <button className="navigation__button" type="button" onClick={handleOpenMenu}>
            <div className="navigation__bar" style={styles.bar1} />
            <div className="navigation__bar" style={styles.bar2} />
            <div className="navigation__bar" style={styles.bar3} />
          </button>
        </>
      )}

      <div className="navigation__link">
        <MenuLink />
      </div>
    </nav>
  );
};

export default Navigation;
