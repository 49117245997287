import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import styles from './stage.module.scss';
import Bird from '../../../../assets/stage_bird.svg';

const Stage = (props) => {
  const { openForm, verification, rent, confirmation } = props;

  const { t } = useTranslation();

  return (
    <ul className={styles.stage}>
      <li className={styles.stage__item}>
        <div className={styles.element}>
          {openForm ? (
            <>
              <div className={styles.element__circleActive}>
                <div className={styles.element__circleDotActive} />
              </div>
              <p className={styles.element__textActive}>{t('stageFirst')}</p>
            </>
          ) : (
            <>
              <div className={styles.element__circle}>
                <img src={Bird} className={styles.element__bird} alt="Ok" />
              </div>
              <p className={styles.element__text}>{t('stageFirst')}</p>
            </>
          )}
        </div>
      </li>
      <li className={styles.stage__item}>
        <div className={styles.element}>
          {openForm && (
            <>
              <div className={styles.element__circle}>
                <div className={styles.element__circleDot} />
              </div>
              <p className={styles.element__text}>{t('stageSecond')}</p>
            </>
          )}
          {verification && (
            <>
              <div className={styles.element__circleActive}>
                <div className={styles.element__circleDotActive} />
              </div>
              <p className={styles.element__textActive}>{t('stageSecond')}</p>
            </>
          )}
          {rent && (
            <>
              <div className={styles.element__circle}>
                <img src={Bird} className={styles.element__bird} alt="Ok" />
              </div>
              <p className={styles.element__textActive}>{t('stageSecond')}</p>
            </>
          )}
          {confirmation && (
            <>
              <div className={styles.element__circle}>
                <img src={Bird} className={styles.element__bird} alt="Ok" />
              </div>
              <p className={styles.element__text}>{t('stageSecond')}</p>
            </>
          )}
        </div>
      </li>
      <li className={styles.stage__item}>
        <div className={styles.element}>
          {confirmation ? (
            <>
              <div className={styles.element__circle}>
                <img src={Bird} className={styles.element__bird} alt="Ok" />
              </div>
              <p className={styles.element__textActive}>{t('stageThird')}</p>
            </>
          ) : (
            <>
              <div className={styles.element__circle}>
                <div className={styles.element__circleDot} />
              </div>
              <p className={styles.element__text}>{t('stageThird')}</p>
            </>
          )}
        </div>
      </li>
    </ul>
  );
};

const mapStateToProps = (state) => ({
  openForm: state.UI.openForm,
  verification: state.UI.verification,
  rent: state.UI.rent,
  confirmation: state.UI.confirmation,
});

export default connect(mapStateToProps)(Stage);
